import { Alert, AlertDescription, AlertIcon, Button } from "@chakra-ui/react";
import { BusinessType } from "@obtainly-v2/enums";
import { BusinessAutocomplete } from "components/business";
import { ClientReferenceAutocomplete } from "components/client-reference";
import { FormGroup } from "components/common";
import { useFormWithSchema } from "hooks";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { RiFileInfoFill } from "react-icons/ri";
import * as Yup from "yup";
import { MultiStepComponentProps } from "./LoanMultiStepForm";

const formSchema = Yup.object({
  clientId: Yup.string().required().label("Client"),
  clientReferenceId: Yup.string().required().label("Client Contact"),
  vendorId: Yup.string().required().label("Vendor"),
});

type FormInputType = Yup.InferType<typeof formSchema>;

export const LoanStepClientInformation = React.forwardRef<
  HTMLButtonElement,
  MultiStepComponentProps
>(({ onSubmit, loanRequest, user, admin, onValidateForm }, nextButtonRef) => {
  const defaultValues: FormInputType = {
    clientId: loanRequest?.clientId || "",
    clientReferenceId: loanRequest?.clientReferenceId || "",
    vendorId: loanRequest?.vendorId || user?.businessId || "",
  };
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid },
  } = useFormWithSchema(formSchema, {
    defaultValues,
  });

  const onSubmitForm = (data: FormInputType) => {
    // final documents to be pushed to form
    onSubmit({ ...data });
  };

  const onPartialSubmitForm = () => {
    const values = getValues();
    onSubmit({ ...values }, true);
  };

  useEffect(() => {
    onValidateForm?.(isValid);
  }, [isValid, onValidateForm]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {!!admin && (
        <FormGroup label="Vendor">
          <Controller
            name="vendorId"
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <BusinessAutocomplete
                businessType={BusinessType.Vendor}
                onChange={(data) => {
                  setValue("clientReferenceId", "");
                  onChange({ target: data });
                  onPartialSubmitForm();
                }}
                errorMessage={error?.message}
                isDisabled
                {...rest}
              />
            )}
          />
        </FormGroup>
      )}

      <FormGroup label="Client that issued LPO">
        <Controller
          name="clientId"
          control={control}
          render={({
            field: { ref, onChange, ...rest },
            fieldState: { error },
          }) => (
            <BusinessAutocomplete
              businessType={BusinessType.Client}
              onChange={(data) => {
                setValue("clientReferenceId", "");
                onChange({ target: data });
                onPartialSubmitForm();
              }}
              errorMessage={error?.message}
              {...rest}
            />
          )}
        />
      </FormGroup>

      <FormGroup label="Client Contact Details">
        <Controller
          name="clientReferenceId"
          control={control}
          render={({
            field: { ref, onChange, ...rest },
            fieldState: { error },
          }) => (
            <ClientReferenceAutocomplete
              placeholder="Enter contact's name"
              vendorId={watch("vendorId")}
              clientId={watch("clientId")}
              onChange={(data) => {
                onChange({ target: data });
                onPartialSubmitForm();
              }}
              errorMessage={error?.message}
              {...rest}
            />
          )}
        />
      </FormGroup>

      <Alert
        status="warning"
        borderRadius="md"
        borderColor="warning.100"
        bg="warning.50"
        alignItems="start"
      >
        <AlertIcon as={RiFileInfoFill} w="24px" h="24px" />
        <AlertDescription>
          We would reach out to this client contact to confirm that the LPO is
          authentic.
        </AlertDescription>
      </Alert>

      <Button type="submit" hidden ref={nextButtonRef} />
    </form>
  );
});

LoanStepClientInformation.displayName = "LoanStepClientInformation";
