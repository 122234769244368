import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Box,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { LoanRequestStatus } from "@obtainly-v2/enums";
import { LoanRequestModel, UserModel } from "@obtainly-v2/schema";
import { VirtualAccountCard } from "components/account";
import { ContentLoader } from "components/common";
import { Card } from "components/common/Card";
import { routes } from "config/routes";
import { fromUnixTime, isAfter } from "date-fns";
import { useLoanRequestPayment } from "hooks";
import Link from "next/link";
import { ElementType, FC, ReactNode, useCallback, useMemo } from "react";
import { RiApps2Fill, RiNotificationBadgeFill } from "react-icons/ri";
import { useQuery } from "react-query";
import { loanRequestService } from "services";
import {
  formatCurrency,
  formatUnixTime,
  getLoanRequestDueDateText,
} from "utils";
import { LoanRequestPaymentSummary } from "./LoanRequestPaymentSummary";

interface LoanRequestCTAProps {
  businessId: string;
  user: UserModel | null;
}

enum PickedLoans {
  Pending,
  Approved,
  Active,
  Overdue,
}

export const LoanRequestCTA: React.FC<LoanRequestCTAProps> = ({
  businessId,
  user,
}) => {
  const { data: loanRequests, isLoading } = useQuery(
    `loan_request_read_many_by_vendor_${businessId}`,
    () => loanRequestService.readManyBy({ vendorId: businessId! }),
    { enabled: !!businessId }
  );

  const [pendingLoan, approvedLoan, activeLoan, overdueLoan] = useMemo(() => {
    const loan = loanRequests?.reduce(
      (loans: Array<LoanRequestModel | null>, currentLoan) => {
        if (
          !loans[PickedLoans.Pending] &&
          currentLoan.status === LoanRequestStatus.Pending
        ) {
          loans[PickedLoans.Pending] = currentLoan;
        }
        if (
          !loans[PickedLoans.Approved] &&
          currentLoan.status === LoanRequestStatus.Approved
        ) {
          loans[PickedLoans.Approved] = currentLoan;
        }
        if (
          !loans[PickedLoans.Active] &&
          currentLoan.status === LoanRequestStatus.Active
        ) {
          loans[PickedLoans.Active] = currentLoan;
        }
        if (
          !loans[PickedLoans.Overdue] &&
          currentLoan.status === LoanRequestStatus.Active &&
          currentLoan?.expiredAt &&
          isAfter(new Date(), fromUnixTime(currentLoan.expiredAt))
        ) {
          loans[PickedLoans.Overdue] = currentLoan;
        }
        return loans;
      },
      [null, null, null, null]
    );

    return loan || [];
  }, [loanRequests]);

  return isLoading ? (
    <Card>
      <ContentLoader isLoading />
    </Card>
  ) : overdueLoan ? (
    <ActiveLoanCTA isOverdue loanRequest={overdueLoan} user={user} />
  ) : activeLoan ? (
    <ActiveLoanCTA loanRequest={activeLoan} user={user} />
  ) : approvedLoan ? (
    <CTAAlert
      status="warning"
      icon={RiNotificationBadgeFill}
      title="Your funding has been approved"
      content={<LoanRequestPaymentSummary isCTA loanRequest={approvedLoan} />}
      cta={[
        {
          type: "link",
          link: `${routes.user.loanRequests}/${approvedLoan._id}/view`,
          label: "View Funding",
        },
      ]}
      backgroundColor="#ECF4FF"
      shadowColor="#D1E5FF"
      mainColor="#016DFF"
    />
  ) : pendingLoan ? (
    <CTAAlert
      status="warning"
      icon={RiNotificationBadgeFill}
      title="Your funding is awaiting approval"
      content={<LoanRequestPaymentSummary isCTA loanRequest={pendingLoan} />}
      cta={[
        {
          type: "link",
          link: `${routes.user.loanRequests}/${pendingLoan._id}/view`,
          label: "View Funding",
        },
      ]}
      backgroundColor="#FFF7E9"
      shadowColor="#FFEBC8"
      mainColor="#ED8937"
    />
  ) : (
    <CTAAlert
      status="success"
      icon={RiApps2Fill}
      title="Request Funding"
      content="Request a funding for your purchase order."
      cta={[
        {
          type: "link",
          link: `${routes.user.loanRequests}/new`,
          label: "Start Application",
        },
      ]}
    />
  );
};

const CTAAlert = ({
  icon,
  status,
  title,
  content,
  cta,
  mainColor = "primary.500",
  backgroundColor = "#ECF4FF",
  shadowColor = "#D1E5FF",
}: {
  icon?: ElementType;
  status: AlertProps["status"];
  title?: string;
  content: ReactNode;
  cta?: Array<
    | {
        label: string;
        type: "link";
        link: string;
        buttonProps?: ButtonProps;
      }
    | {
        label: string;
        type: "button";
        buttonProps?: ButtonProps;
      }
  >;
  mainColor?: string;
  backgroundColor?: string;
  shadowColor?: string;
}) => {
  const buttonProps = useCallback(
    (baseProps?: ButtonProps) => {
      const props: ButtonProps =
        baseProps?.variant === "outline"
          ? {
              bg: "transparent",
              color: mainColor,
              borderColor: shadowColor,
              borderWidth: "1px",
              _hover: { bg: "transparent" },
            }
          : {
              bg: mainColor,
              borderColor: mainColor,
              color: "white",
              _hover: { bg: mainColor },
            };
      return { ...baseProps, ...props };
    },

    [mainColor, shadowColor]
  );
  return (
    <Alert
      p="20px"
      status={status}
      bg={backgroundColor}
      borderRadius="lg"
      alignItems="flex-start"
      flexDir="column"
      boxShadow={`0px 4px 0px ${shadowColor}`}
    >
      <AlertIcon as={icon} color={mainColor} width="36px" height="36px" />

      <Box mt="10px">
        {!!title && (
          <AlertTitle
            fontSize="2xl"
            fontFamily="archivo"
            fontWeight="semibold"
            color={`${status}.700`}
          >
            {title}
          </AlertTitle>
        )}

        {typeof content === "string" ? (
          <Text mt={title ? "4px" : ""}>{content}</Text>
        ) : (
          content
        )}
        <Flex align="center" gridGap="6px" mt="10px">
          {!!cta?.length &&
            cta.map((action, index) =>
              action.type === "link" ? (
                <Link href={action.link} key={index}>
                  <a>
                    <Button size="sm" {...buttonProps(action?.buttonProps)}>
                      {action.label}
                    </Button>
                  </a>
                </Link>
              ) : (
                <Button
                  key={index}
                  size="sm"
                  variant="outline"
                  {...buttonProps(action?.buttonProps)}
                >
                  {action.label}
                </Button>
              )
            )}
        </Flex>
      </Box>
    </Alert>
  );
};

type ActiveLoanCTAProps = {
  user: UserModel | null;
  loanRequest: LoanRequestModel;
  isOverdue?: boolean;
};

const ActiveLoanCTA: FC<ActiveLoanCTAProps> = ({
  loanRequest,
  user,
  isOverdue,
}) => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { cashBack } = useLoanRequestPayment(loanRequest);

  return (
    <>
      <CTAAlert
        status="warning"
        icon={RiNotificationBadgeFill}
        title={
          isOverdue
            ? "Your funding is overdue for payment"
            : "You have an active funding"
        }
        content={
          <>
            {isOverdue && (
              <Text>
                Daily overdue charges shall be applied to your accrued amount.
              </Text>
            )}
            <LoanRequestPaymentSummary isCTA loanRequest={loanRequest} />
          </>
        }
        cta={[
          {
            type: "button",
            label: "Payback",
            buttonProps: getButtonProps(),
          },
          {
            type: "link",
            link: `${routes.user.loanRequests}/${loanRequest._id}/view`,
            label: "View Funding",
            buttonProps: { variant: "outline" },
          },
        ]}
        {...(isOverdue
          ? {
              backgroundColor: "#FFF5F5",
              shadowColor: "#FED7D7",
              mainColor: "#C53030",
            }
          : {
              backgroundColor: "#E9FFF0",
              shadowColor: "#CAFFDB",
              mainColor: "#48BB78",
            })}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody maxH="auto">
            <Text fontSize="xl" fontWeight="semibold" mb="10px">
              Payback Options
            </Text>
            <VStack gap="10px" mb="10px" align="stretch">
              <Box>
                <Text color="primary.600" fontWeight="semibold">
                  Option 1{" "}
                </Text>
                Transfer money into your Obtainly virtual account{" "}
                {!isOverdue && (
                  <>
                    on or before{" "}
                    <Text as="span" fontWeight="medium">
                      {getLoanRequestDueDateText(loanRequest)}
                    </Text>
                  </>
                )}
              </Box>
              <Box>
                <Text color="primary.600" fontWeight="semibold">
                  Option 2{" "}
                </Text>
                Ensure your client pays into your Obtainly virtual account{" "}
                {!isOverdue && (
                  <>
                    on or before{" "}
                    <Text as="span" fontWeight="medium">
                      {getLoanRequestDueDateText(loanRequest)}
                    </Text>
                  </>
                )}
              </Box>
              {!!user && !!user.business && (
                <VirtualAccountCard user={user} business={user.business} />
              )}
              {cashBack && (
                <Box>
                  <Text fontStyle="italic" color="gray.700">
                    Pay today ({formatUnixTime(new Date(), "do MMM, yyyy")}) and
                    get{" "}
                    {formatCurrency(cashBack, {
                      showCurrency: true,
                    })}{" "}
                    cash back.
                  </Text>
                </Box>
              )}
            </VStack>
            <Flex justify="flex-end">
              <Button size="sm" onClick={onClose}>
                Okay
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
