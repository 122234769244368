import {
  Badge,
  Box,
  Divider,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SubjectType } from "@obtainly-v2/enums";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { useAuth } from "hooks";
import { FC } from "react";
import { useQuery } from "react-query";
import { activityLogService } from "services";
import { formatUnixTime } from "utils";

interface LoanRequestCommunicationProps {
  loanRequest: LoanRequestModel;
}

const LoanRequestCommunication: FC<LoanRequestCommunicationProps> = ({
  loanRequest,
}) => {
  const {
    auth: { admin },
  } = useAuth();

  const { data, isLoading } = useQuery(
    `activity_read_logs_${loanRequest?._id}_${SubjectType?.LoanRequest}`,
    () =>
      activityLogService.readLog(loanRequest?._id, SubjectType?.LoanRequest),
    {
      enabled: !!SubjectType?.LoanRequest && !!loanRequest?._id && !!admin,
    }
  );

  return (
    <>
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!data?.length}
        errorMessage="Communication trail not found"
      />

      {!isLoading && data ? (
        <VStack align="stretch" divider={<Divider />} gap={1}>
          {data.map((item) => (
            <SimpleGrid templateColumns="1fr" alignItems="center">
              <Box>
                <Badge colorScheme="info">{item.level}</Badge>
              </Box>
              <Box>
                <Text mt="4px" fontSize="md" fontWeight="medium">
                  {item.message}
                </Text>
                <Text fontSize="sm" color="gray.700">
                  {formatUnixTime(
                    item?.createdAt * 1000,
                    "dd MMM yyyy, hh:mm a"
                  )}
                </Text>
              </Box>
            </SimpleGrid>
          ))}
        </VStack>
      ) : null}
    </>
  );
};

export default LoanRequestCommunication;
