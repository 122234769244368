import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  ScaleFade,
  VStack,
} from "@chakra-ui/react";
import { FormGroup, PoweredBadge } from "components/common";
import { Card, CardBody, CardHeader } from "components/common/Card";
import {
  motion,
  useElementScroll,
  useSpring,
  useTransform,
} from "framer-motion";
import { useFormWithSchema } from "hooks";
import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { RiFileInfoFill } from "react-icons/ri";
import * as Yup from "yup";
import LoanAgreement from "./LoanAgreement";
import { MultiStepComponentProps } from "./LoanMultiStepForm";
import { LoanRequestSummary } from "./LoanRequestSummary";

const formSchema = Yup.object({
  accepted: Yup.boolean()
    .test("test checkbox is checked", "", (value) => value ?? false)
    .required()
    .label("Terms and Conditions"),
});

type FormInputType = Yup.InferType<typeof formSchema>;

export const LoanStepReview = React.forwardRef<
  HTMLButtonElement,
  MultiStepComponentProps
>(({ onSubmit, loanRequest, onValidateForm }, nextButtonRef) => {
  const defaultValues: FormInputType = {
    accepted: loanRequest?.acceptedTerms || false,
  };

  const viewPortRef = useRef<HTMLDivElement>(null);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useFormWithSchema(formSchema, {
    defaultValues,
  });

  const onSubmitForm = (data: FormInputType) => {
    // final documents to be pushed to form
    onSubmit({ acceptedTerms: data.accepted });
  };

  const { scrollYProgress } = useElementScroll(viewPortRef);
  const animationValues = useTransform(
    scrollYProgress,
    [0, 0.001, 0.98, 1],
    [-100, 0, 0, -100]
  );

  const springAnimation = useSpring(animationValues);

  useEffect(() => {
    onValidateForm?.(isValid);
  }, [isValid, onValidateForm]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {!!loanRequest && <LoanRequestSummary loanRequest={loanRequest} />}

      <Card>
        <CardHeader
          boxShadow="sm"
          px="20px"
          pt="20px"
          pb="15px"
          mb="0px"
          heading="Terms and Conditions"
        />

        <CardBody position="relative" p="0px" overflow="hidden">
          <motion.div
            style={{
              top: springAnimation,
              left: 0,
              right: 0,
              position: "absolute",
            }}
          >
            <Box
              bgColor="warning.50"
              pt="15px"
              pb="5px"
              px="20px"
              boxShadow="sm"
              w="full"
            >
              <FormGroup>
                <Controller
                  name="accepted"
                  control={control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Checkbox {...rest} isChecked={value} alignItems="start">
                      I agree to terms and conditions.
                    </Checkbox>
                  )}
                />
              </FormGroup>
            </Box>
          </motion.div>
          <VStack
            ref={viewPortRef}
            p="20px"
            gap="10px"
            maxH="300px"
            align="stretch"
            textAlign="justify"
            overflowY="auto"
          >
            {!!loanRequest && <LoanAgreement loanRequest={loanRequest} />}
            <FormGroup>
              <Controller
                name="accepted"
                control={control}
                render={({
                  field: { value, ...rest },
                  fieldState: { error },
                }) => (
                  <>
                    <Checkbox
                      {...rest}
                      isChecked={value}
                      alignItems="start"
                      onFocus={(checkboxElement) =>
                        checkboxElement.currentTarget.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        })
                      }
                    >
                      I have read and agree to terms and conditions stated
                      above.
                    </Checkbox>
                    <ScaleFade in={!!error} initialScale={0}>
                      <Alert
                        status="error"
                        borderRadius="md"
                        borderColor="critical.100"
                        bg="critical.50"
                        alignItems="start"
                        mt="10px"
                        color="critical.600"
                      >
                        <AlertIcon as={RiFileInfoFill} w="24px" h="24px" />
                        <AlertDescription>
                          Agree to terms and conditions by checking the box
                          above before submitting funding request.
                        </AlertDescription>
                      </Alert>
                    </ScaleFade>
                  </>
                )}
              />
            </FormGroup>
          </VStack>
        </CardBody>
      </Card>

      <Button type="submit" hidden ref={nextButtonRef} />

      <Flex mt="30px">
        <PoweredBadge />
      </Flex>
    </form>
  );
});

LoanStepReview.displayName = "LoanStepReview";
