import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { RiFileInfoFill } from "react-icons/ri";

interface LoanRequestDraftPromptProps {
  isOpen: boolean;
  onClosePrompt: (fromStart?: boolean) => void;
}

const LoanRequestDraftPrompt: FC<LoanRequestDraftPromptProps> = ({
  isOpen,
  onClosePrompt,
}) => {
  return isOpen ? (
    <Alert
      status="info"
      borderRadius="md"
      borderColor="info.100"
      bg="info.50"
      alignItems="start"
    >
      <AlertIcon as={RiFileInfoFill} w="24px" h="24px" />
      <AlertDescription>
        <Text fontSize="lg" mb="10px">
          You have a draft funding request. Do you wish to continue from where
          you stopped?
        </Text>
        <Flex mb="15px" gap="10px" wrap="wrap">
          <Button
            size="sm"
            colorScheme="primary"
            variant="outline"
            onClick={() => onClosePrompt(true)}
          >
            No, start new funding request
          </Button>
          <Button
            size="sm"
            autoFocus
            colorScheme="primary"
            onClick={() => onClosePrompt()}
          >
            Yes, continue
          </Button>
        </Flex>
      </AlertDescription>
    </Alert>
  ) : null;
};

export default LoanRequestDraftPrompt;
