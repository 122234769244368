import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DateInput, FileUploadField, FormGroup } from "components/common";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from "react-hook-form";
import { RiArrowDropDownLine } from "react-icons/ri";
import { LoanRequestInputType } from "./LoanRequestForm";
import { DocumentTag } from "./LoanStepPurchaseOrder";

interface LoanRequestDocumentsFormProp<T extends Record<string, any>> {
  control: Control<T>;
  errors?: FieldErrors<T>;
}

export const LoanRequestDocumentsForm: React.FC<
  LoanRequestDocumentsFormProp<LoanRequestInputType>
> = ({ control }) => {
  const { fields, remove, append, update } = useFieldArray({
    name: "documents",
    control,
  });

  const appendNew = (tag: string) => {
    append({
      tag,
      value: null,
      status: "active",
    });
  };

  return (
    <>
      {!!fields.length && (
        <VStack
          p="16px"
          mb="16px"
          align="stretch"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="md"
          gap="10px"
        >
          {fields.map((document, index) => {
            return !!document ? (
              <FormGroup
                key={index}
                label={document.tag.split("_").join(" ").toUpperCase()}
                mb="0px"
              >
                <FileUploadField
                  autoStartUpload
                  label={
                    typeof document?.value === "string"
                      ? document?.value
                      : document?.value?.name || ""
                  }
                  fileUrl={
                    typeof document?.value === "string"
                      ? document?.value
                      : document?.value?.fileUrl || ""
                  }
                  onSuccess={(data) =>
                    update(index, { ...fields[index], value: data })
                  }
                  onDelete={async () => remove(index)}
                  tag={document.tag}
                />
              </FormGroup>
            ) : null;
          })}
          <FormGroup label="Date of previous payment from client">
            <Controller
              name="lastLpoSubmittedAt"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <DateInput
                    value={value}
                    onChange={(newValue) => {
                      onChange({
                        target: {
                          value: newValue,
                        },
                      });
                    }}
                    size="md"
                  />

                  {!!error?.message && (
                    <Text fontSize="sm" color="red.500" mt="2px">
                      {error?.message}
                    </Text>
                  )}
                </>
              )}
            />
          </FormGroup>
        </VStack>
      )}
      <Box>
        <Menu>
          <MenuButton as={Button} rightIcon={<RiArrowDropDownLine />}>
            Add document
          </MenuButton>
          <MenuList>
            {Object.entries(DocumentTag).map(([key, value]) => (
              <MenuItem key={key} onClick={() => appendNew(value)}>
                {value.split("_").join(" ").toUpperCase()}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};
