import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { UploadModel } from "@obtainly-v2/schema/src/upload";
import { FileUploadField } from "components/common";
import { Card, CardBody, CardHeader } from "components/common/Card";
import React from "react";
import { useQuery } from "react-query";
import { clientReferenceService } from "services";
import { formatUnixTime } from "utils";
import { NewLoanRequestModel } from "./LoanMultiStepForm";
import { LoanRequestPaymentSummary } from "./LoanRequestPaymentSummary";

interface LoanRequestSummaryProps {
  loanRequest: NewLoanRequestModel;
}

export const LoanRequestSummary: React.FC<LoanRequestSummaryProps> = ({
  loanRequest,
}) => {
  const { data: clientReference, isLoading } = useQuery(
    `read_client_reference_${loanRequest.clientReferenceId}`,
    () => clientReferenceService.read(loanRequest.clientReferenceId),
    { enabled: !!loanRequest.clientReferenceId }
  );

  return (
    <Grid
      mb="20px"
      gridGap="20px"
      gridTemplateColumns={["1fr", "1fr", "1fr", "2fr 1fr"]}
    >
      <Card py="20px">
        <CardHeader
          as={Flex}
          px="20px"
          alignItems="center"
          heading="Summary of funding request"
        />
        <VStack alignItems="stretch" spacing="15px" divider={<Divider />}>
          <Box px="20px">
            <LoanRequestPaymentSummary isDraft loanRequest={loanRequest} />
          </Box>

          <Box px="20px" justifyContent="space-between">
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Documents
            </Text>
            <VStack align="stretch">
              {loanRequest.documents?.map((document, index) => (
                <FileUploadField
                  key={index}
                  businessId={(document.value as UploadModel).businessId}
                  tag={document.tag}
                  label={(document.value as UploadModel).name}
                  fileUrl={(document.value as UploadModel).fileUrl}
                  readOnly
                />
              ))}
            </VStack>

            <Text
              fontSize="sm"
              color="gray.600"
              fontWeight="medium"
              mt="20px"
              mb="2px"
            >
              Date of previous payment from client
            </Text>
            <Text>
              {formatUnixTime(
                loanRequest.lastLpoSubmittedAt! * 1000,
                "dd MMM yyyy"
              )}
            </Text>
          </Box>
        </VStack>
      </Card>

      <GridItem>
        <Card py="20px">
          <CardBody py="0">
            <CardHeader heading="Your Client Details" />
          </CardBody>

          <VStack alignItems="stretch" spacing="15px" px="20px">
            <GridItem>
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                Company Name
              </Text>
              <Text>{clientReference?.client?.name}</Text>
            </GridItem>

            <GridItem>
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                Contact Name
              </Text>
              <Text>{loanRequest?.referenceName ?? clientReference?.name}</Text>
            </GridItem>

            <GridItem>
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                Contact Email
              </Text>
              <Text>
                {loanRequest?.referenceEmail ?? clientReference?.email}
              </Text>
            </GridItem>

            <GridItem>
              <Text fontSize="sm" color="gray.600" fontWeight="medium">
                Contact Role
              </Text>
              <Text>{loanRequest?.referenceRole ?? clientReference?.role}</Text>
            </GridItem>
          </VStack>
        </Card>
      </GridItem>
    </Grid>
  );
};
