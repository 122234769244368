import {
  Box,
  Button,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { LoanRequestStatus } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  LoanRequestModel,
  RateSettingModel,
  UserModel,
} from "@obtainly-v2/schema";
import { FormBaseProps, OtpDialog } from "components/common";
import { Card, CardBody, CardHeader } from "components/common/Card";
import { useToast } from "hooks";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { loanRequestService } from "services";
import { LoanMultiStepFormHeader } from "./LoanMultiStepFormHeader";
import { LoanStepClientInformation } from "./LoanStepClientInformation";
import { LoanStepDetails } from "./LoanStepDetails";
import { LoanStepPurchaseOrder } from "./LoanStepPurchaseOrder";
import { LoanStepReview } from "./LoanStepReview";

interface LoanMultiStepFormProps
  extends FormBaseProps<Partial<LoanRequestModel>, LoanRequestModel> {
  user?: UserModel | null;
  admin?: AdministratorModel | null;
  draft?: string;
  onUpdateDraft?: (draft?: string) => void;
  onValidateForm?: (isValid: boolean) => void;
}

export interface NewLoanRequestModel
  extends Omit<LoanRequestModel, "_id" | "createdAt" | "updatedAt"> {
  rateSettingId: string;
  rateSetting: RateSettingModel | null;
  acceptedTerms: boolean;
  currentStep: number;
  lastOtpRequestAt: number | null;
}

export interface MultiStepComponentProps {
  ref: React.MutableRefObject<any>;
  onSubmit: (data: Partial<NewLoanRequestModel>, partial?: boolean) => void;
  loanRequest?: NewLoanRequestModel;
  user: UserModel | null;
  admin: AdministratorModel | null;
  onValidateForm?: (isValid: boolean) => void;
}

export const loanRequestSteps: Array<{
  title: string;
  description: string;
  component: (props: MultiStepComponentProps) => React.ReactNode;
}> = [
  {
    title: "Funding Details",
    description: "Some details about the funding you're requesting",
    component: (props: MultiStepComponentProps) => (
      <LoanStepDetails {...props} />
    ),
  },
  {
    title: "Purchase Order",
    description:
      "Share the details of the purchase order you want to finance with this funding",
    component: (props: MultiStepComponentProps) => (
      <LoanStepPurchaseOrder {...props} />
    ),
  },
  {
    title: "Your Client's Information",
    description:
      "Choose from the list of available clients or add a new client with a reference staff that we can contact to validate your PO",
    component: (props: MultiStepComponentProps) => (
      <LoanStepClientInformation {...props} />
    ),
  },
  {
    title: "Review Application",
    description: "Review your funding request and sign contract below.",
    component: (props: MultiStepComponentProps) => (
      <LoanStepReview {...props} />
    ),
  },
];

const getInitialLoanRequest = (draft?: string) => {
  const defaultValues = {
    amount: 0,
    clientId: "",
    clientReferenceId: "",
    duration: 0,
    creditProviderId: null,
    status: LoanRequestStatus.Pending,
    interest: 0,
    referenceEmail: null,
    referenceName: null,
    referenceRole: null,
    vendorId: "",
    documents: [] as any,
    acceptedTerms: false,
    rateSettingId: "",
    rateSetting: null,
    currentStep: 0,
    lastOtpRequestAt: null,
  };
  if (!draft) return defaultValues;
  try {
    return JSON.parse(draft);
  } catch (error) {
    return defaultValues;
  }
};

export const LoanMultiStepForm: FC<LoanMultiStepFormProps> = ({
  admin,
  user,
  onSuccess,
  onError,
  onSubmit,
  draft,
  onUpdateDraft,
}) => {
  const { toast } = useToast();

  const formNavigationProps = useBreakpointValue<FlexProps>({
    base: {
      position: "fixed",
      left: "0px",
      right: "0px",
      bottom: "62px",
      bgColor: "white",
      padding: "10px 15px",
      borderTop: "gray.100 1px solid",
      borderBottom: "gray.100 1px solid",
      align: "center",
      justify: "space-between",
      zIndex: "sticky",
    },
    md: {
      position: "initial",
      left: "auto",
      right: "auto",
      bottom: "auto",
      bgColor: "transparent",
      padding: "0px",
      borderTop: "none",
      borderBottom: "none",
      align: "center",
      justify: "space-between",
      zIndex: undefined,
    },
  });
  const nextActionButtonRef = useRef<any>();
  const [isCurrentFormValid, setCurrentFormValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showOtpDialog, setOtpDialog] = useState(false);
  const [loanRequest, setLoanRequest] = useState<NewLoanRequestModel>(() =>
    getInitialLoanRequest(draft)
  );

  const activeStep = loanRequest.currentStep || 0;

  const lastStep = loanRequestSteps.length - 1;
  const isLastStep = activeStep === lastStep;

  const { mutate: mutateLoanRequestCreate, isLoading: isCreatingLoanRequest } =
    useMutation(loanRequestService.createWithOtp);

  const dispatchFormValue = async (
    data: Partial<NewLoanRequestModel>,
    partial = false
  ) => {
    setLoading(true);
    setLoanRequest((prev) => ({ ...prev, ...data }));

    // don't move to next step
    if (partial) {
      setLoading(false);
      return;
    }

    if (isLastStep) {
      initiateOtpVerification();
      return;
    }

    // move to next step
    // setActiveStep((prev) => prev + 1);
    setLoanRequest((prev) => ({
      ...prev,
      ...data,
      currentStep: activeStep + 1,
    }));
    setLoading(false);
    return;
  };

  const submitForm = useCallback(
    (otp: string) => {
      return new Promise<void>(async (resolve, reject) => {
        setLoading(true);
        if (onSubmit) {
          await onSubmit?.({ ...loanRequest });
          onUpdateDraft?.();
          setLoading(false);
          resolve();
        } else {
          const serializedDocuments: any =
            loanRequest.documents?.map((document) => ({
              ...document,
              value:
                typeof document.value === "string"
                  ? document.value
                  : document.value._id,
            })) || [];

          mutateLoanRequestCreate(
            {
              params: { ...loanRequest, documents: serializedDocuments },
              otp,
            },
            {
              onSuccess: ({ data }) => {
                onSuccess?.(data);
                toast({
                  description: "Submitted",
                  status: "success",
                });
                onUpdateDraft?.();
                resolve();
              },
              onError: (error: any) => {
                const userCannotSubmitRequest = error?.response?.status === 403;
                if (userCannotSubmitRequest) {
                  setLoanRequest((prev) => ({
                    ...prev,
                    lastOtpRequestAt: null,
                  }));
                  closeOtpDialog();
                }
                const message =
                  error?.response?.data?.message || "Unknown error occurred";
                onError?.(new Error(message));
                toast({
                  description: message,
                  status: "error",
                });
                reject();
              },
              onSettled: () => setLoading(false),
            }
          );
        }
      });
    },
    [
      loanRequest,
      mutateLoanRequestCreate,
      onSuccess,
      onError,
      onSubmit,
      toast,
      onUpdateDraft,
    ]
  );

  const closeOtpDialog = () => {
    setOtpDialog(false);
    setLoading(false);
  };
  const initiateOtpVerification = () => setOtpDialog(true);

  useEffect(() => {
    // update draft whenever input values are updated
    if (loanRequest.amount > 0) {
      onUpdateDraft?.(JSON.stringify(loanRequest));
    }
  }, [onUpdateDraft, loanRequest]);

  // look into draft
  // is OTP dialog worthy of display?
  useEffect(() => {
    const initialRequest = getInitialLoanRequest(draft);
    const lastOtpRequestAt = initialRequest?.lastOtpRequestAt;
    const currentStep = initialRequest?.currentStep;
    const acceptedTerms = initialRequest?.acceptedTerms;
    if (lastOtpRequestAt && acceptedTerms && currentStep === lastStep) {
      initiateOtpVerification();
    }
  }, [draft, lastStep]);

  return (
    <>
      <Grid
        gap="20px"
        gridTemplateColumns={["auto", "auto", "300px auto"]}
        gridTemplateRows={["auto 1fr auto", "auto 1fr auto", "1fr auto"]}
      >
        <GridItem rowSpan={["auto", "auto", 2]}>
          <LoanMultiStepFormHeader activeStep={activeStep} />
        </GridItem>
        <GridItem>
          <Card>
            <CardHeader
              heading={loanRequestSteps[activeStep].title}
              description={loanRequestSteps[activeStep].description}
              pt="20px"
              px="20px"
              mb={0}
            />
            <CardBody>
              {loanRequestSteps[activeStep].component({
                ref: nextActionButtonRef,
                onSubmit: dispatchFormValue,
                loanRequest,
                admin: admin || null,
                user: user || null,
                onValidateForm: (formState) => setCurrentFormValid(formState),
              })}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem>
          <Box display={["block", "block", "none"]} h="100px"></Box>
          <Flex {...formNavigationProps}>
            <Button
              variant="outline"
              size={["sm", "sm", "md"]}
              disabled={activeStep <= 0 || isLoading}
              onClick={() =>
                setLoanRequest((prev) => ({
                  ...prev,
                  currentStep: activeStep - 1,
                }))
              }
            >
              Back
            </Button>

            <Button
              colorScheme="primary"
              size={["sm", "sm", "md"]}
              onClick={() => nextActionButtonRef.current?.click()}
              disabled={isLoading || !isCurrentFormValid}
            >
              {isLastStep ? "Agree and Submit Request" : "Next"}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <OtpDialog
        isOpen={showOtpDialog}
        onClose={closeOtpDialog}
        onSubmit={submitForm}
        onOtpRequest={(timestamp) =>
          dispatchFormValue({ lastOtpRequestAt: timestamp }, true)
        }
        lastRequestedAt={loanRequest.lastOtpRequestAt}
      />
    </>
  );
};
