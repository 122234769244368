import {
  Badge,
  Box,
  Divider,
  SimpleGrid,
  Text,
  ThemingProps,
  VStack,
} from "@chakra-ui/react";
import { AccountLedger } from "@obtainly-v2/enums";
import { AccountLedgerLogModel, LoanRequestModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { useLoanRequestPayment } from "hooks";
import { FC, useMemo } from "react";
import { formatCurrency, formatUnixTime } from "utils";

interface LoanRequestLogProps {
  loanRequest: LoanRequestModel;
}

export const LoanRequestLog: FC<LoanRequestLogProps> = ({ loanRequest }) => {
  const { auditData, isLoading } = useLoanRequestPayment(loanRequest);
  const logs = useMemo(() => {
    return auditData?.data?.ledger_logs || [];
  }, [auditData]);

  return (
    <Box>
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!logs.length}
        errorMessage="No record found"
      />
      {!isLoading && !!logs.length && (
        <VStack align="stretch" divider={<Divider />} gap={1}>
          {logs.map((log) => (
            <LogItem key={log._id} log={log} />
          ))}
        </VStack>
      )}
    </Box>
  );
};

type LogItemProps = {
  log: AccountLedgerLogModel;
};

const LogItem: FC<LogItemProps> = ({ log }) => {
  const colorScheme: ThemingProps["colorScheme"] = useMemo(() => {
    switch (log.ledger) {
      case AccountLedger.Principal:
        return "info";
      case AccountLedger.Pending:
        return "warning";
      case AccountLedger.Penalty:
        return "critical";
      default:
        return "gray";
    }
  }, [log]);
  return (
    <Box>
      <SimpleGrid alignItems="start" gridTemplateColumns="1fr auto">
        <Box>
          <Badge colorScheme={colorScheme}>{log.ledger}</Badge>
        </Box>
        <Box textAlign="right">
          <Text fontWeight="medium">
            {formatCurrency(Number(log.value || 0) / 100, {
              showCurrency: true,
            })}
          </Text>
        </Box>
      </SimpleGrid>
      <Box mt="4px">
        <Text fontSize="md">
          {JSON.parse(log?.description || "{}")?.narration || "-"}
        </Text>
        <Text fontSize="sm" color="gray.700">
          {formatUnixTime(log.created * 1000, "dd MMM yyyy, hh:mm a")}
        </Text>
      </Box>
    </Box>
  );
};
