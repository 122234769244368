import { Box, Divider, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import {
  LoanRequestDerivedStatus,
  LoanRequestStatus,
} from "@obtainly-v2/enums";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { FC, useMemo } from "react";
import { formatUnixTime, getLoanRequestDerivedStatus } from "utils";
import { LoanRequestStatusBadge } from "./LoanRequestStatusBadge";

interface LoanRequestHistoryProps {
  loanRequest: LoanRequestModel;
}

export const LoanRequestHistory: FC<LoanRequestHistoryProps> = ({
  loanRequest,
}) => {
  const history = useMemo(() => {
    let output: Array<{
      activity: string;
      status: LoanRequestStatus;
      derivedStatus?: LoanRequestDerivedStatus;
      timestamp: number;
    }> = [];

    const derivedStatus = getLoanRequestDerivedStatus(loanRequest);

    if (loanRequest.approvedAt) {
      output.push({
        activity: "Request approved",
        status: LoanRequestStatus.Approved,
        timestamp: loanRequest.approvedAt,
      });
    }
    if (loanRequest.activatedAt) {
      output.push({
        activity: "Funds disbursed",
        status: LoanRequestStatus.Active,
        timestamp: loanRequest.activatedAt,
      });
      if (loanRequest.expiredAt && derivedStatus) {
        output.push({
          activity: "Payment overdue",
          status: LoanRequestStatus.Active,
          derivedStatus,
          timestamp: loanRequest.expiredAt,
        });
      }
    }
    if (loanRequest.paidBackAt) {
      output.push({
        activity: "Paid",
        timestamp: loanRequest.paidBackAt,
        status: LoanRequestStatus.Paid,
        derivedStatus,
      });
    }
    if (loanRequest.createdAt) {
      output.push({
        activity: "Request submitted",
        status: LoanRequestStatus.Pending,
        timestamp: loanRequest.createdAt,
      });
    }

    return output.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
  }, [loanRequest]);

  return (
    <Box>
      <ContentLoader
        contentUnavailable={!history.length}
        errorMessage="No record found"
      />
      {!!history.length && (
        <VStack align="stretch" divider={<Divider />} gap={1}>
          {history.map((record) => (
            <HistoryItem {...record} />
          ))}
        </VStack>
      )}
    </Box>
  );
};

type HistoryItemProps = {
  activity: string;
  status: LoanRequestStatus;
  derivedStatus?: LoanRequestDerivedStatus;
  timestamp: number;
};

const HistoryItem: FC<HistoryItemProps> = ({
  activity,
  status,
  timestamp,
  derivedStatus,
}) => {
  return (
    <SimpleGrid templateColumns="1fr 2fr" alignItems="center">
      <Box>
        <LoanRequestStatusBadge status={status} derivedStatus={derivedStatus} />
      </Box>
      <Box>
        <Text mt="4px" fontSize="md" fontWeight="medium">
          {activity}
        </Text>
        <Text fontSize="sm" color="gray.700">
          {formatUnixTime(timestamp * 1000, "dd MMM yyyy, hh:mm a")}
        </Text>
      </Box>
    </SimpleGrid>
  );
};
