import { Box, Button, Text } from "@chakra-ui/react";
import { DateInput, FileUploadField, FormGroup } from "components/common";
import { useFormWithSchema } from "hooks";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import * as Yup from "yup";
import {
  MultiStepComponentProps,
  NewLoanRequestModel,
} from "./LoanMultiStepForm";

export enum DocumentTag {
  RequestLPO = "request_lpo",
  PreviousLPO = "previous_lpo",
}

const requiredDocuments = {
  [DocumentTag.RequestLPO]: {
    title: "Purchase Order",
    description: "LPO from your client",
  },
  [DocumentTag.PreviousLPO]: {
    title: "Previous Purchase Order",
    description: "LPO of previous successful job from your client",
  },
};

const formSchema = Yup.object({
  [DocumentTag.RequestLPO]: Yup.mixed()
    .required((messageParams) => `${messageParams.label} is required`)
    .label(requiredDocuments[DocumentTag.RequestLPO].title),
  [DocumentTag.PreviousLPO]: Yup.mixed()
    .required((messageParams) => `${messageParams.label} is required`)
    .label(requiredDocuments[DocumentTag.PreviousLPO].title),
  lastLpoSubmittedAt: Yup.number()
    .min(1, (messageParams) => `Select ${messageParams.label.toLowerCase()}`)
    .required((messageParams) => `${messageParams.label} is required`)
    .label("Date of previous payment from client"),
});

type FormInputType = Yup.InferType<typeof formSchema>;

const getInitialValues = (loanRequest?: NewLoanRequestModel) => {
  return {
    [DocumentTag.RequestLPO]:
      loanRequest?.documents?.find(
        (document) => document.tag === DocumentTag.RequestLPO
      )?.value || null,
    [DocumentTag.PreviousLPO]:
      loanRequest?.documents?.find(
        (document) => document.tag === DocumentTag.PreviousLPO
      )?.value || null,
    lastLpoSubmittedAt: loanRequest?.lastLpoSubmittedAt || 0,
  };
};

export const LoanStepPurchaseOrder = React.forwardRef<
  HTMLButtonElement,
  MultiStepComponentProps
>(({ onSubmit, loanRequest, user, onValidateForm }, nextButtonRef) => {
  const defaultValues: FormInputType = getInitialValues(loanRequest);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isValid },
  } = useFormWithSchema(formSchema, {
    defaultValues,
  });

  const onSubmitForm = (data: FormInputType) => {
    // final documents to be pushed to form
    onSubmit({
      documents: Object.entries({
        request_lpo: data.request_lpo,
        previous_lpo: data.previous_lpo,
      }).map(([key, value]) => ({
        tag: key,
        value,
        status: "active",
      })) as any,
      lastLpoSubmittedAt: data.lastLpoSubmittedAt,
    });
  };

  const onPartialSubmitForm = () => {
    const values = getValues();
    onSubmit(
      {
        documents: Object.entries({
          request_lpo: values.request_lpo,
          previous_lpo: values.previous_lpo,
        }).map(([key, value]) => ({
          tag: key,
          value,
          status: "active",
        })) as any,
        lastLpoSubmittedAt: values.lastLpoSubmittedAt,
      },
      true
    );
  };

  useEffect(() => {
    onValidateForm?.(isValid);
  }, [isValid, onValidateForm]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {Object.entries(DocumentTag).map(([tagKey, tagValue]) => (
        <FormGroup
          key={tagKey}
          label={requiredDocuments[tagValue].title}
          helperText={requiredDocuments[tagValue].description}
        >
          <Controller
            name={tagValue}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Box>
                <FileUploadField
                  autoStartUpload
                  tag={tagValue}
                  businessId={user?.businessId || null}
                  label={value?.name || ""}
                  fileUrl={value?.fileUrl || ""}
                  onSuccess={(data) => {
                    onChange({
                      target: {
                        value: { ...data },
                      },
                    });
                    onPartialSubmitForm();
                  }}
                />
                {!!error?.message && (
                  <Text fontSize="sm" color="red.500" mt="2px">
                    {error?.message}
                  </Text>
                )}
              </Box>
            )}
          />
        </FormGroup>
      ))}
      <FormGroup
        label="Date of previous payment from client"
        helperText="When did you get paid for the previous LPO from this Client? Please note that we will need this date to carry out proper assessment"
      >
        <Controller
          name="lastLpoSubmittedAt"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Box>
              <DateInput
                value={value}
                onChange={(newValue) => {
                  onChange({
                    target: {
                      value: newValue,
                    },
                  });
                  onPartialSubmitForm();
                }}
                size="md"
              />

              {!!error?.message && (
                <Text fontSize="sm" color="red.500" mt="2px">
                  {error?.message}
                </Text>
              )}
            </Box>
          )}
        />
      </FormGroup>
      <Button type="submit" hidden ref={nextButtonRef} />
    </form>
  );
});

LoanStepPurchaseOrder.displayName = "LoanStepPurchaseOrder";
