import {
  Avatar,
  Box,
  Flex,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Card, CardBody } from "components/common/Card";
import { FC } from "react";
import { RiCheckLine } from "react-icons/ri";
import { loanRequestSteps } from "./LoanMultiStepForm";

interface LoanMultiStepFormHeaderProps {
  activeStep: number;
}

export const LoanMultiStepFormHeader: FC<LoanMultiStepFormHeaderProps> = ({
  activeStep,
}) => {
  const hideOtherSteps = useBreakpointValue([true, true, false]);
  return (
    <Card>
      <CardBody>
        <VStack align="stretch" rowGap="15px">
          {loanRequestSteps.map((step, index) => (
            <Flex
              key={index}
              gap="10px"
              align="center"
              my="0px !important"
              display={
                hideOtherSteps && index !== activeStep ? "none" : undefined
              }
            >
              {index === activeStep ? (
                <Avatar
                  size="sm"
                  bgColor="success.50"
                  color="success.600"
                  icon={<RiCheckLine size="18px" />}
                  boxShadow="0px 1px 0px #CAFFDB"
                />
              ) : (
                <Avatar
                  name={`${index + 1}`}
                  size="sm"
                  bgColor="gray.200"
                  color="gray.600"
                  fontSize="16px"
                  fontWeight="medium"
                  fontFamily="Archivo"
                />
              )}
              <Box>
                <Text fontSize="sm" color="gray.500">
                  Step {index + 1}
                </Text>
                <Text fontSize="md" fontWeight="medium">
                  {step.title}
                </Text>
              </Box>
            </Flex>
          ))}
        </VStack>
      </CardBody>
    </Card>
  );
};
