import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Text,
} from "@chakra-ui/react";
import { BusinessType } from "@obtainly-v2/enums";
import { RateSettingModel } from "@obtainly-v2/schema";
import { BusinessAutocomplete } from "components/business";
import { ContentLoader, FormField, FormGroup } from "components/common";
import { RateDurationAutocomplete } from "components/rate-setting";
import { useFormWithSchema } from "hooks";
import pluralize from "pluralize";
import React, { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { RiFileInfoFill } from "react-icons/ri";
import { useMutation } from "react-query";
import { rateSettingService } from "services";
import {
  formatCurrency,
  getInterestValue,
  getLoanRequestDurationFormat,
} from "utils";
import * as Yup from "yup";
import { MultiStepComponentProps } from "./LoanMultiStepForm";

const formSchema = Yup.object({
  amount: Yup.number()
    .typeError("Amount must be a number")
    .min(1000)
    .required()
    .label("Amount"),
  duration: Yup.number()
    .min(1, (field) => field.label)
    .typeError((field) => field.label)
    .required((field) => field.label)
    .label("Specify when you'd like to pay back"),
  interest: Yup.number().required(),
  vendorId: Yup.string().required().label("Vendor"),
  rateSettingId: Yup.string().required().label("Interest rate option"),
  rateSetting: Yup.mixed<RateSettingModel>()
    .nullable()
    .label("Interest rate option"),
});

type FormInputType = Yup.InferType<typeof formSchema>;

export const LoanStepDetails = React.forwardRef<
  HTMLButtonElement,
  MultiStepComponentProps
>(({ onSubmit, loanRequest, admin, user, onValidateForm }, nextButtonRef) => {
  const defaultValues: FormInputType = {
    amount: loanRequest?.amount || 0,
    duration: loanRequest?.duration || 0,
    interest: loanRequest?.interest || 0,
    vendorId: loanRequest?.vendorId || user?.businessId || "",
    rateSettingId: loanRequest?.rateSettingId || "",
    rateSetting: loanRequest?.rateSetting || null,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    control,
    setValue,
  } = useFormWithSchema(formSchema, {
    defaultValues,
  });

  const onSubmitForm = (data: FormInputType) => {
    onSubmit(data);
  };

  const [amount, duration, vendorId, rateSetting] = watch([
    "amount",
    "duration",
    "vendorId",
    "rateSetting",
  ]);

  const setValueOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true,
  };

  const { mutate, isLoading: isLoadingRateSetting } = useMutation(
    `rate_setting_available_durations_${duration}_${vendorId}`,
    rateSettingService.readAvailableRates
  );

  useEffect(() => {
    onValidateForm?.(isValid);
  }, [isValid, onValidateForm]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {!!admin && (
        <FormGroup label="Vendor">
          <Controller
            name="vendorId"
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <BusinessAutocomplete
                businessType={BusinessType.Vendor}
                onChange={(data) => {
                  onChange({ target: data });
                  setValue("duration", 0);
                  setValue("interest", 0);
                  setValue("rateSettingId", "");
                  setValue("rateSetting", null);
                }}
                errorMessage={error?.message}
                {...rest}
              />
            )}
          />
        </FormGroup>
      )}

      <FormGroup label="Amount">
        <FormField
          type="number"
          autoComplete="amount"
          placeholder="Enter amount"
          errorMessage={errors.amount?.message}
          {...register("amount")}
        />
      </FormGroup>

      <FormGroup label="When would you like to pay back?">
        <RateDurationAutocomplete
          vendorId={vendorId}
          value={duration}
          onChange={(payload) => {
            setValue("rateSettingId", "");
            setValue("rateSetting", null);
            setValue("interest", 0);
            setValue("duration", payload);
            mutate(
              { duration: payload, businessId: vendorId },
              {
                onSuccess: (data) => {
                  if (data.length) {
                    setValue("rateSettingId", data[0]._id, setValueOptions);
                    setValue("rateSetting", data[0], setValueOptions);
                    setValue("interest", data[0].interestRate, setValueOptions);
                    setValue("duration", payload, setValueOptions);
                  }
                },
              }
            );
          }}
          errorMessage={
            errors.duration?.message ||
            (errors.rateSettingId?.message &&
              "No available rates for the selected duration. Select another duration")
          }
        />
      </FormGroup>

      {isLoadingRateSetting ? (
        <ContentLoader isLoading h="auto" py="5" />
      ) : amount > 0 && rateSetting ? (
        <PaybackSummaryCard amount={amount} rateSetting={rateSetting} />
      ) : null}
      <Button type="submit" hidden ref={nextButtonRef} />
    </form>
  );
});

LoanStepDetails.displayName = "LoanStepDetails";

type PaybackSummaryCardProps = {
  amount: number;
  rateSetting: RateSettingModel;
};

const PaybackSummaryCard: FC<PaybackSummaryCardProps> = (props) => {
  const {
    amount,
    rateSetting: { duration, interestRate: interest },
  } = props;
  const { ratePerInterval, intervalUnit, intervalCount, suffix } =
    getLoanRequestDurationFormat({ amount, duration, interest });
  return (
    <Alert
      status="warning"
      borderRadius="md"
      borderColor="warning.100"
      bg="warning.50"
      alignItems="start"
    >
      <AlertIcon as={RiFileInfoFill} w="24px" h="24px" />
      <AlertDescription>
        Collect{" "}
        <Text display="inline" fontWeight="medium">
          {formatCurrency(amount, { showCurrency: true })}
        </Text>{" "}
        to pay back{" "}
        <Text display="inline" fontWeight="medium">
          {formatCurrency(getInterestValue(amount, interest) + Number(amount), {
            showCurrency: true,
          })}
        </Text>{" "}
        in {intervalCount} {suffix} ({duration} {pluralize("day", duration)})
        after disbursement at {ratePerInterval}% interest rate per{" "}
        {intervalUnit}.
      </AlertDescription>
    </Alert>
  );
};
