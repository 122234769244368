import { Box, Divider, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { ContentLoader } from "components/common";
import { FC, Fragment, useMemo } from "react";
import { useQuery } from "react-query";
import { accountService } from "services";
import { formatCurrency, formatUnixTime, parseJSON } from "utils";

interface LoanRequestPenaltyProps {
  loanRequest: LoanRequestModel;
}

export const LoanRequestPenalty: FC<LoanRequestPenaltyProps> = ({
  loanRequest,
}) => {
  const { data, isLoading } = useQuery(
    `account_penalty_funding_request_${loanRequest._id}_${loanRequest.vendorId}`,
    () =>
      accountService.penaltyFundingRequest({
        vendorId: loanRequest.vendorId,
        loanRequestId: loanRequest._id,
      }),
    {
      enabled: !!loanRequest._id && !!loanRequest.vendorId,
    }
  );
  const penalties = useMemo(() => {
    return data?.data?.ledger_logs || [];
  }, [data]);

  return (
    <Box>
      <ContentLoader
        isLoading={isLoading}
        contentUnavailable={!penalties.length}
        errorMessage="No record found"
      />
      {!isLoading && !!penalties.length && (
        <VStack align="stretch" divider={<Divider />} gap={1}>
          {penalties.map((penalty: any, index: number) => (
            <Fragment key={index}>
              <Box>
                <SimpleGrid alignItems="start" gridTemplateColumns="1fr auto">
                  <Text fontSize="" color="gray.700">
                    {formatUnixTime(
                      penalty.created * 1000,
                      "dd MMM yyyy, hh:mm a"
                    )}
                  </Text>
                  <Text
                    color={
                      penalty.value < 0
                        ? "critical.600"
                        : penalty > 0
                        ? "success.600"
                        : "gray.800"
                    }
                    fontWeight="medium"
                    fontSize="lg"
                    textAlign="right"
                  >
                    {formatCurrency(Number(penalty.value || 0) / 100, {
                      showCurrency: true,
                    })}
                  </Text>
                </SimpleGrid>
                <Box mt="4px">
                  <Text fontSize="md">
                    {parseJSON<any>(penalty.description)?.narration || "-"}
                  </Text>
                </Box>
              </Box>
            </Fragment>
          ))}
        </VStack>
      )}
    </Box>
  );
};
