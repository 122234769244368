import {
  Box,
  Heading,
  ListItem as ChakraListItem,
  ListItemProps,
  ListProps,
  OrderedList as ChakraList,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { LoanRequestModel } from "@obtainly-v2/schema";
import Link from "next/link";
import { FC, PropsWithChildren } from "react";
import { useQuery } from "react-query";
import { userService } from "services";
import { formatCurrency, getLoanRequestDurationFormat } from "utils";
import { NewLoanRequestModel } from "./LoanMultiStepForm";

interface LoanAgreementProps {
  loanRequest: LoanRequestModel | NewLoanRequestModel;
}

const ListTitle: FC<PropsWithChildren<TextProps>> = ({
  children,
  ...props
}) => {
  return (
    <Text
      display="inline-block"
      fontSize="lg"
      fontWeight="semibold"
      mb="10px"
      {...props}
    >
      {children}
    </Text>
  );
};

const ListItem: FC<PropsWithChildren<ListItemProps>> = ({
  children,
  ...props
}) => {
  return (
    <ChakraListItem mb="15px" {...props}>
      {children}
    </ChakraListItem>
  );
};

const OrderedList: FC<PropsWithChildren<ListProps>> = ({
  children,
  ...props
}) => {
  return (
    <ChakraList mb="15px" {...props}>
      {children}
    </ChakraList>
  );
};

const LoanAgreement: FC<LoanAgreementProps> = ({ loanRequest }) => {
  const amount = formatCurrency(loanRequest.amount, { showCurrency: true });
  const { duration } = loanRequest;
  const { intervalCount, ratePerInterval, intervalUnit, suffix } =
    getLoanRequestDurationFormat(loanRequest);

  const { data: user, isLoading: isLoadingUser } = useQuery(
    `business_read_by_businessId_${loanRequest.vendorId}`,
    () => userService.readBy({ businessId: loanRequest.vendorId }),
    { enabled: !!loanRequest.vendorId }
  );

  return (
    <Box className="nested-scope-list" fontSize={["sm", "sm", "md"]}>
      <Heading fontSize="md" textAlign="center" mb="12px">
        <Text>SUPLIAS TECHNOLOGIES LIMITED</Text>
        <Text>LENDER TERMS &amp; CONDITIONS</Text>
      </Heading>
      <OrderedList>
        {/* introduction */}
        <ListItem>
          <b>Introduction</b>
          <OrderedList>
            {/* 1.1 */}
            <ListItem>
              Before requesting for a loan on the Platform (as defined below),
              we strongly recommend that You carefully read, understand, and
              accept the Terms and Conditions (“T &amp; C”) herein as they will
              govern your use of and access to the Services (as defined below).
              Please note that the use of and access to the Services are subject
              to the following terms; if You do not agree to all these terms,
              You may not use or access the Services in any manner.
            </ListItem>
            <ListItem>
              This Terms &amp; Conditions is a legally binding agreement between
              you (“User” “Customer” or “You”) and SUPLIAS TECHNOLOGIES LIMITED
              duly incorporated and licensed under the laws of the Federal
              Republic of Nigeria (“SUPLIAS TECHNOLOGIES”, “Suplias”, “We” or
              “us”) for the facilitation of credit facility/loan to be provided
              by our partner Microfinance bank via Obtainly or the Platform.
              This Terms of Use sets out the complete terms and conditions which
              shall be applicable to the loan provided to You via the Platform.
            </ListItem>
            <ListItem>
              Please carefully read and fully understand all provisions of this
              T &amp; C, in particular, terms that exclude or limit our
              liabilities, terms that limit your rights, terms of dispute
              resolution and governing law etc. After registering on the
              Platform and then accepting and inserting your electronic
              signature in the relevant column, you will be deemed to have
              accepted and confirmed an intention to use the Platform for the
              accessing the Services. If you do not agree with the T &amp; C,
              please do not click “Agree”. Kindly note that if you do not agree
              with the T &amp; C, you will not be able to use the Platform for
              the purpose of accessing the Services.
            </ListItem>
            <ListItem>
              We may amend this T &amp; C from time to time. For example, we may
              amend this it in case of any update to the features or functions
              of the Services or any changes to the regulatory requirements. In
              case we amend this T &amp; C, the updated version will be
              published three days prior to the effective date. We will also
              promptly update the information of the last update date and the
              effective date at the top of this T&amp;C. We will use reasonable
              efforts to provide a reasonable notice to You in order to inform
              you of any material change to this T &amp; C (such as, a notice on
              our Platform) and you shall promptly read our notification and
              check for such changes. Your continued use or access to the
              Services after the renewal of this T &amp; C shall be deemed as
              your acceptance of the updated T &amp; C. If you do not agree to
              the updated T &amp; C, you must cease to use or access to the
              Services and pay back any monies already due.
            </ListItem>
            <ListItem>
              This T &amp; C contains the terms governing your use of and access
              to the Platform and all affiliated websites or mobile applications
              owned and operated by Us for the purpose of the Services.
              &ldqout;You&ldqout; and &ldqout;your&ldqout; mean the person who
              uses or accesses the Services. If you do not wish to be bound by
              this Agreement, do not access and or Use the Platform for the
              purpose of accessing the Services.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 2 */}
        <ListItem>
          <ListTitle>Definition and Interpretation</ListTitle>
          <OrderedList>
            <ListItem>
              In this T&amp;C, save where the context otherwise requires,
              references to:
              <ul>
                <ListItem>
                  an individual or individuals shall include his/her or their
                  respective personal representatives;{" "}
                </ListItem>
                <ListItem>
                  the singular includes the plural and vice versa and any gender
                  includes any other gender;
                </ListItem>
                <ListItem>
                  all the headings and sub-headings in this T &amp; C are for
                  convenience only and are not to be considered for the purposes
                  of interpretation of this T &amp; C; and{" "}
                </ListItem>
                <ListItem>
                  any recitals and schedules shall be deemed to form part of
                  this T &amp; C.
                </ListItem>
              </ul>
            </ListItem>
            <ListItem>
              The following words and expressions in this T &amp; C (save where
              the context requires otherwise) bear the following meanings:
              <Text>
                <b>Account</b> means your Obtainly account on the Platform;
              </Text>
              <Text>
                <b>Business Day</b> means a day other than a Saturday, Sunday or
                national or public holiday in which banks are open for business
                in Nigeria;
              </Text>
              <Text>
                <b>Credentials</b> means your personal credentials used to
                access the Platform and operate your Account;
              </Text>
              <Text>
                <b>Equipment</b> includes your mobile phone handset and/or other
                equipment which when used together enables you to access the
                Network and is a supported device for the purposes of the
                Platform;
              </Text>
              <Text>
                <b>Force Majeure</b> means events, circumstances or causes
                beyond the reasonable control of Suplias making Suplias&apos;
                performance of its obligations inadvisable, commercially
                impracticable, illegal, or impossible, including but not limited
                to acts of God, war, pandemics, lockdowns, strikes or labour
                disputes, embargoes or government orders;
              </Text>
              <Text>
                <b>Nigeria</b> means the Federal Republic of Nigeria
              </Text>
              <Text>
                <b>MFB</b> means BestStar Microfinance Bank Limited which has
                partnered with Suplias for the purpose of providing credit
                facilities/loans to User of the Platform or any other service
                provider as may be chosen by Suplias from time to time.
              </Text>
              <Text>
                <b>Mobile Phone</b> means the telephone device together with
                requisite accessories, and equipment attachments;
              </Text>
              <Text>
                <b>Personal Information</b> means the information about the User
                obtained by Suplias and MFB in connection with the Services
                being provided by them;
              </Text>
              <Text>
                <b>Platform</b> means Obtainly, the mobile application software,
                the data supplied with the software and the associated Services.
                This is also referred to as the “Platform”;
              </Text>
              <Text>
                <b>Privacy Policy</b> means the Suplias&apos; policy document
                that sets out the basis on which any personal data we collect
                from you, or that you provide to us, will be processed by us;
              </Text>
              <Text>
                <b>Repayment Period</b> means the time period by which a User
                will repay the Loan gotten from MFB;
              </Text>
              <Text>
                <b>Request</b> means a request or instruction received by
                MFB/Suplias from you or purportedly from you through the
                Platform or any of our customer support channels and upon which
                MFB is authorised to act;
              </Text>
              <Text>
                <b>Services</b> shall include any form of lending services or
                products that MFB may offer you pursuant to this T &amp; C and
                which you may from time to time subscribe to; and “Service”
                shall be construed accordingly;
              </Text>
              <Text>
                <b>Third Party Partners</b> means payment companies, or any
                other person (entity) that provides any form of service or
                information to us for the provision of the Services; and
              </Text>
              <Text>
                <b>Transaction Fees</b> includes any fees and charges payable by
                a User in relation to the Services as published by Suplias on
                the website, the Platform or by such other means as Suplias
                shall in its sole discretion determine. Transaction Fees are
                subject to change solely at Suplias&apos; discretion.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 3 */}
        <ListItem>
          <ListTitle>The Services, Purpose, Acceptance and Use</ListTitle>
          <OrderedList>
            <ListItem>
              <ListTitle>The Services and Purpose</ListTitle>
              <OrderedList>
                <ListItem>
                  MFB shall provide a loan in the sum of <b>{amount}</b> to You
                  to enable You fund LPOs (Purchase Orders) via the Platform.
                  This Loan is provided for a duration of{" "}
                  <b>
                    {intervalCount} {suffix} ({duration} days)
                  </b>{" "}
                  at the interest rate of{" "}
                  <b>{`${ratePerInterval}% per ${intervalUnit}`}</b>. (Please
                  note that the interest rate is subject to change and that you
                  will be notified ahead of any change in the interest rate.)
                </ListItem>
                <ListItem>
                  The Services offered via the Platform can only be utilised by
                  persons over the age of 18 that are legally resident in
                  Nigeria and have valid bank accounts. To provide you with the
                  Services, MFB and Suplias reserve the right to verify any
                  information provided in this regard. The Services are provided
                  by MFB or any other service provider of Suplias&apos;
                  choosing.
                </ListItem>
                <ListItem>
                  Suplias reserves the right to connect you with MFB for the
                  purpose of providing the Services via the Platform, and to
                  revoke same at any stage at its sole and absolute discretion
                  and without assigning any reason or giving any notice thereto.
                  You acknowledge that all intellectual property rights in the
                  Platform and the Technology anywhere in the world belong to us
                  or our licensors, and that rights in the Platform are licensed
                  (not sold or assigned) to you in accordance with this T &amp;
                  C, and that you have no rights in, or to, the Platform or the
                  Technology other than the right to use each of them in
                  accordance with the terms of this T &amp; C. You acknowledge
                  that you have no right to have access to the Platform in
                  source-code form.
                </ListItem>
                <ListItem>
                  The Platform can be accessed by either downloading the
                  application and using it on a Mobile Phone, or via the Web. It
                  allows You to, among other things, digitally open an Account
                  on the Platform.
                </ListItem>
                <ListItem>
                  This T&amp;C and any other agreements between You and Suplias,
                  constitutes a binding contract, and sets out the terms and
                  conditions on which You may use the Platform for accessing the
                  Services. The T&amp;C and any amendments or variations thereto
                  shall take effect on their date of publication or on such
                  other date as may be expressly communicated to You, whichever
                  is earlier.
                </ListItem>
                <ListItem>
                  The Platform is intended solely for individuals who are 18
                  years old or older. Any access to or use of the Platform by
                  anyone under 18 years is unauthorised, unlicensed, and in
                  violation of this T&amp;C. By accessing or using the Platform,
                  You (if an individual) represent and warrant that You are 18
                  years old or older.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <ListTitle>Acceptance</ListTitle>
              <OrderedList>
                <ListItem>
                  This T&amp;C governs your use of the Platform in relation to
                  the Services. By downloading, registering, accessing and/or
                  using the Platform or even attempting to interact with any
                  part of the Platform for the purpose of accessing the
                  Services, You acknowledge that You have read, understood, and
                  agree to be legally bound by this T&amp;C as they appear on
                  the Platform as at the time you access and use the Platform.
                  If You do not agree with this T&amp;C, do not access or use
                  the Platform. You must agree to this T&amp;C before accessing
                  the Platform by ticking the “AGREE” box below. This action
                  shall constitute your acceptance of this T&amp;C and shall
                  govern your access and usage of the Platform. IF YOU DO NOT
                  AGREE TO ABIDE BY THESE TERMS AND CONDITIONS, PLEASE DO NOT
                  CONTINUE TO ACCESS OR USE THE PLATFORM FOR ACCESSING THE
                  SERVICES.
                </ListItem>
                <ListItem>
                  By signing up to use the Platform and to access the Services,
                  You agree to comply with and be bound by the terms and
                  conditions governing the interaction between You and Suplias
                  and You aﬃrm that the terms and conditions are without
                  prejudice to any right that Suplias has with respect to the
                  Platform in law or otherwise.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              <ListTitle>Use of the Platform</ListTitle>

              <OrderedList>
                <ListItem>
                  Your use of the Platform is governed by the version of the
                  T&amp;C in effect on the date that you access the Platform. We
                  reserve the right to amend, change, vary or alter the T&amp;C
                  at any time with or without notice to You. If You access or
                  use the Platform after the amended T&amp;C has been published,
                  You will be deemed to have agreed to the T&amp;C as amended,
                  changed, varied, or altered.
                </ListItem>
                <ListItem>
                  You acknowledge and accept that Suplias oﬀers the Platform
                  only electronically. Any query and complaint You may have
                  relating to the Platform shall be addressed to Suplias through
                  the designated customer service email channel, and any
                  complaints as to the loans in particular shall be directed to
                  the MFB through the Platform.
                </ListItem>
                <ListItem>
                  For the avoidance of doubt, You acknowledge and accept that
                  You will not be allowed or entitled to receive or demand the
                  Services from the offices of Suplias.
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 4 */}
        <ListItem>
          <ListTitle>Access to the Platform</ListTitle>
          <OrderedList>
            <ListItem>
              To enable You access the services provided via the Platform, We
              require You to provide certain details about yourself or your
              business in order to sign you up successfully. You hereby agree
              that You will provide true, accurate, current, and complete
              registration information about your business or yourself. We may
              also require that You provide relevant financial information, risk
              preferences and any other information that We may require from
              time to time (“User Information”). You hereby authorise Us and MFB
              to verify the User Information from independent sources that may
              be available to Us. You shall promptly update your User via the
              Platform in the event of any change or modification to the details
              previously provided by You.
            </ListItem>
            <ListItem>
              You understand and agree that the ownership and related interests
              of Account belongs to Suplias, and you shall only have the right
              to use such Account upon first log in. The Account shall be
              limited to your personal use and, without Suplias&apos; written
              approval, giving, borrowing, renting, transfer and sale of the
              Account in any manner or otherwise licensing others to use this
              Account in any forms shall be prohibited. If Suplias has found or
              there is a reasonable cause to believe that the user of the
              Account is not the initial registrant of such Account, Suplias
              shall have the right to suspend or terminate the services to such
              Account and deregister the Account without prior notification to
              you. You shall be solely responsible for all the losses arising to
              you and/or your customers from the wipe and loss of all the data
              and information caused thereby. You shall be responsible to keep
              your Account and password secure and confidential.
            </ListItem>
            <ListItem>
              We reserve the right to request further information from You
              pertaining to your use of the Platform at any time. Failure to
              provide such information within the time required by Us may result
              in the Us declining or terminating your access to the Platform.
            </ListItem>
            <ListItem>
              We reserve the right to decline your application to access the
              Platform or to revoke the same at any stage at our sole discretion
              and without assigning any reason or giving any notice thereto or
              in accordance with notice periods permitted in law.
            </ListItem>
            <ListItem>
              We reserve the right to introduce any of the various product
              offerings in accordance with this T&amp;C and/or such other
              Platform-specific terms and condition and/or user guides as may be
              published/released by us from time to time.
            </ListItem>
            <ListItem>
              In order to access the loans provided by MFB, We may create a
              virtual account in your name and on your behalf. This virtual
              account will be used to hold the funds/loans to be disbursed by
              MFB to you to enable You carry out the client&apos;s instructions
              as stated in the. You hereby agree that the loan(s) to be provided
              via the Platform shall be disbursed into the virtual account
              created for you, and You agree to be bound by the repayment terms
              as shown on the Plaform in relation to each loan(s).
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 5 */}
        <ListItem>
          <ListTitle>Obligations of the User and MFB</ListTitle>
          <OrderedList>
            <ListItem>
              The User shall:
              <OrderedList>
                <ListItem>
                  provide all necessary documentation and information as may be
                  required by MFB and Suplias for its onboarding on the Platform
                  and for providing the loan;
                </ListItem>
                <ListItem>
                  comply with all the rules governing the use of the Platform;
                </ListItem>
                <ListItem>
                  have obtained all necessary permits, licences or
                  authorisations required to enable it obtain and receive loans;
                </ListItem>
                <ListItem>
                  request for a loan from MFB and pay all the necessary fees;
                </ListItem>
                <ListItem>
                  repay the loan and the accompanying interest at the agreed
                  interest rate of{" "}
                  <b>{`${ratePerInterval}% per ${intervalUnit}`}</b> within the
                  agreed period. For the purpose of calculating the duration of
                  a loan a single day into a new week shall be counted as a full
                  week;
                </ListItem>
                <ListItem>
                  provide collateral/security for the loan where required; and
                </ListItem>
                <ListItem>
                  not use the loan for any illegal purpose and to use the loan
                  for financing transactions on the Platform.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              The User undertakes to conduct its business on the Platform;
              <OrderedList>
                <ListItem>
                  with reasonable care and skill, and to always co-operate with
                  and act in good faith towards Suplias, MFB and any other
                  relevant parties;
                </ListItem>
                <ListItem>
                  in compliance with the T&amp;C and any other policies and
                  guidelines as may be published by Suplias on the Platform from
                  time to time; and
                </ListItem>
                <ListItem>
                  in accordance with generally accepted ethical business
                  practices and industry best practice.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              The User further agrees that:
              <OrderedList>
                <ListItem>
                  it shall be bound by the loan offer made by MFB as soon as the
                  loan is approved;
                </ListItem>
                <ListItem>
                  it shall repay all loans granted to it on or before the due
                  date specified in this T &amp; C and any other relevant offer
                  letter issued by MFB without any delay, and where in default,
                  the User further undertakes to pay any penalties imposed on it
                  by MFB and/or Suplias;
                </ListItem>
                <ListItem>
                  uplias shall not be liable or responsible for any loss
                  suffered if a Your client refuses to pay for the LPO job done.
                  Further to the above, You as a User hereby agree that whether
                  or not Your client pays You, You shall repay the loan plus the
                  accompanying interest as required by this T &amp; C; and
                </ListItem>
                <ListItem>
                  Suplias is authorised to automatically set off any monies in
                  its possession held on your behalf, in relation to any loans
                  that have become due.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              MFB shall under this T &amp; C;
              <OrderedList>
                <ListItem>
                  offer a loan to You on the Platform subject to the T &amp; C;
                </ListItem>
                <ListItem>
                  ensure You are able to access a loan for a term of up to 12
                  weeks;
                </ListItem>
                <ListItem>
                  be responsible for confirming Your identity, credit worthiness
                  or bona fides, or otherwise vet them; and
                </ListItem>
                <ListItem>
                  may share the details of all loans provided to You pursuant to
                  this T&amp;C, with the Central Bank of Nigeria, credit bureaus
                  and other relevant regulators or financial institutions, in
                  accordance with the applicable regulations.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              You hereby acknowledge that Suplias;
              <OrderedList>
                <ListItem>
                  is not a party to any loan or contract for a loan provided to
                  You via the Platform;
                </ListItem>
                <ListItem>
                  is not involved in any transaction between a User and MFB in
                  any way, save that we connect Users to MFB for the purpose of
                  obtaining a loan;
                </ListItem>
                <ListItem>
                  is not an agent for either MFB or You, and accordingly, we
                  will not be liable to any person in relation to the provision
                  of a loan through the Platform; furthermore, we are not
                  responsible for the enforcement of any contractual obligations
                  arising out of a contract for the loan or for the purchase of
                  any goods and/or services and we will have no obligation to
                  mediate between the parties to any such contract; and
                </ListItem>
                <ListItem>
                  may share the details of all loans provided to You pursuant to
                  this T&amp;C with the Central Bank of Nigeria, credit bureaus
                  and other relevant regulators or financial institutions, in
                  accordance with the applicable regulations.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              We do not warrant or represent:
              <OrderedList>
                <ListItem>
                  the completeness or accuracy of any information provided by
                  MFB to You on the Platform;
                </ListItem>
                <ListItem>
                  that the MFB shall provide a loan to You at all times and upon
                  your request;
                </ListItem>
                <ListItem>
                  that the Platform will operate without fault; or
                </ListItem>
                <ListItem>
                  that the Platform or any service on the Platform will remain
                  available.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              We do not guarantee any commercial results concerning the use of
              the Platform.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 6 */}
        <ListItem>
          6. Prohibited Uses
          <OrderedList>
            <ListItem>
              You agree that You shall not do the following:
              <ul style={{ listStyleType: "decimal" }}>
                <li>
                  use the Platform in any manner that could damage or overburden
                  Suplias&apos; server, or any network connected to
                  Suplias&apos; server, or interfere with any other
                  party&ldqout;s use of the Platform; or
                </li>
                <li>
                  use the Platform for any illegal or unlawful or malicious
                  activity, or for activities that will be deemed improper under
                  any law in force in Nigeria, which includes but is not limited
                  to money laundering, financing of terrorism, fraud,
                  racketeering, amongst others; or
                </li>
                <li>
                  use, post or transmit any information, software or other
                  material that contains a virus or other harmful component;{" "}
                </li>
                <li>
                  willfully provide or give any information (personal or
                  financial) which you know to be untrue, false, wrong,
                  misleading, inaccurate or incomplete which may disrupt the
                  effective provision of the Services by MFB and the operation
                  of the Platform or which will prevent, preclude or hinder
                  Suplias from being able to give an accurate, true or detailed
                  information to other users;{" "}
                </li>
                <li>
                  restrict or inhibit any other user from using and enjoying any
                  interactive features of the Platform;
                </li>
                <li>
                  use any program, means, spider or “bot” to gather or “harvest”
                  any information or obtain by any means, any content, or
                  information not intentionally made available through the
                  Platform;
                </li>
                <li>
                  post or transmit any unlawful, threatening, abusive,
                  libellous, defamatory, vulgar, obscene, pornographic, profane
                  or indecent information of any kind, including without
                  limitation, any transmissions constituting or encouraging
                  conduct that would constitute a criminal offence, give rise to
                  civil liability or otherwise violate any local, state,
                  national or international law;
                </li>
                <li>
                  post or transmit any information, software, image or other
                  material which violates or infringes on the rights of others,
                  including material which is an invasion of privacy or
                  publicity rights, or which is protected by copyright,
                  trademark or other proprietary right or derivative works with
                  respect thereto, without first obtaining permission from the
                  owner or rights holder;
                </li>
                <li>
                  post, transmit or upload any confidential, proprietary or
                  trade secret information without first obtaining permission
                  from the owner or rights holder;{" "}
                </li>
                <li>
                  alter, damage or delete any content or other communication
                  that is not your own content or to otherwise interfere with
                  the ability of others to access the Platform;
                </li>
                <li>
                  transmit any advertisements, solicitations, schemes, spam,
                  flooding, or other unsolicited email or unsolicited
                  communications via the Platform;
                </li>
                <li>
                  use or attempt to use this Platform to gain unauthorised
                  access to Suplias&apos; servers;{" "}
                </li>
                <li>
                  advocate illegal activity or demonstrate an intent to commit
                  an illegal act or violate any law;{" "}
                </li>
                <li>
                  claim a relationship with or speak for any individual,
                  business, association, institution, or other organisation for
                  which you are not authorised to claim such a relationship;{" "}
                </li>
                <li>
                  impersonate or misrepresent your connection to any other
                  entity or person or otherwise manipulate headers or
                  identifiers to disguise the origin of any content;
                </li>
                <li>
                  violate any operating rule, policy or guideline of internet
                  access provider or online service; assist any third party in
                  engaging in any activity that is prohibited by these T&amp;C;{" "}
                </li>
                <li>
                  impersonate any person or entity, falsely state or otherwise
                  misrepresent your affiliation with any person or entity, or
                  use or provide any fraudulent, misleading, or inaccurate
                  information;{" "}
                </li>
                <li>
                  or access or use (or attempt to access or use) another
                  User&apos;s profile without permission or solicit another
                  User&apos;s login information.
                </li>
              </ul>
            </ListItem>
            <ListItem>
              You agree that We may take civil or criminal actions against You
              if We have reason to believe that You have used or attempted to
              use the Platform in any manner prohibited above.
            </ListItem>
            <ListItem>
              We reserve the right to monitor use of the Platform to determine
              compliance with these T&amp;C.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 7 */}
        <ListItem>
          <ListTitle>Communication</ListTitle>
          <Text>
            You consent to Us sending all notifications, notices, records,
            statements, communications, updates, records, and any other
            information regarding the Platform provided to You (“Correspondence
            and Communication”) electronically. Where applicable, You will be
            responsible for any charges, costs or expenses incurred for sending
            any Correspondence and Communication to You.
          </Text>
        </ListItem>

        {/* chapter 8 */}
        <ListItem>
          <ListTitle>Transmission of Personal Information</ListTitle>
          <OrderedList>
            <ListItem>
              Your use of the Platform will involve the transmission of your
              Personal Information to third party service provider(s). In view
              of your right to provide consent for the transmission of your
              Personal Information, You consent to the transmission of such
              information through the Platform and represent that such consent
              shall be effective each time You use the Platform. You hereby
              agree that We reserve the right to request for further information
              from You pertaining to your access or use of the Platform.
              Personal Information as used here includes any identifier such as
              a name, identification number, location data, an online identifier
              or one or more factors specific to the physical, physiological,
              genetic, mental economic, cultural or social identity of a person.
              It also includes an address, photo, an email address, bank
              details, posts on social networking websites, medical information,
              and other unique identifiers like a MAC address, IP address, IMEI
              number, IMSI number, SIM, Personal Identifiable Information (PII)
              and others.
            </ListItem>

            <ListItem>
              The transmission, use and/or analysis of your personal data or
              information shall be processed by Us in accordance with the
              Nigeria Data Protection Regulation 2019 as may be amended from
              time to time and any other data protection regulation in force.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 9 */}
        <ListItem>
          <ListTitle>Usage monitoring</ListTitle>
          <Text>
            We reserve the right to access, archive, or monitor your use of the
            Platform in accordance with the applicable laws of the Federal
            Republic of Nigeria. By using the Platform, You accept our right to
            access, archive, or monitor usage to ensure service quality, or to
            evaluate the Platform, the security of the Platform, compliance with
            the T &amp; C, or for any other reason. You agree that our
            monitoring activities will not entitle You to any cause of action or
            other right with respect to the way We monitor the usage of the
            Platform and enforce, or fail to enforce, the T &amp; C. You further
            agree that in no event will Suplias be liable for any damages
            incurred by You because of our usage monitoring.
          </Text>
        </ListItem>

        {/* chapter 10 */}
        <ListItem>
          <ListTitle>
            Copyright, Trademark, and other Intellectual Property Rights
          </ListTitle>
          <OrderedList>
            <ListItem>
              You acknowledge that the Platform is protected by applicable
              copyrights, trademarks, trade secrets, patents, proprietary
              rights, and other intellectual property laws, and that these
              rights are valid and protected in all forms, media, and
              technologies existing now or developed in the future. Accordingly,
              You may not copy, distribute or modify the Platform, including any
              text, graphics, user interface design or logos, except as
              explicitly authorised by Us in writing.
            </ListItem>
            <ListItem>
              You acknowledge that all intellectual property rights in the
              Platform and the Technology anywhere in the world belong to us,
              and that rights in the Platform are not sold or assigned to you in
              accordance with this T&amp;C, and that you have no rights in, or
              to, the Platform other than the right to use it in accordance with
              the terms of this T&amp;C. You acknowledge that you have no right
              to have access to the Platform&apos;s in source-code form.{" "}
            </ListItem>
            <ListItem>
              The trademarks, service marks, slogans, designs and logos
              appearing on this Platform are the property of Suplias or the
              party that provided the trademark, service marks, slogans, designs
              and logos to Suplias, and any party that provided trademarks,
              service marks, slogans, designs and logos for use on the Platform
              retain all rights with respect to any of their respective
              trademarks, service marks and logos appearing in this Platform.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 11 */}
        <ListItem>
          <ListTitle>Connectivity</ListTitle>
          <Text>
            You agree that You are responsible for the means You use to access
            the Platform and understand that your hardware, software, the
            Internet, your telecommunications service provider, and other third
            parties involved in connecting You to the Platform may not perform
            as intended or desired.
          </Text>
        </ListItem>

        {/* chapter 12 */}
        <ListItem>
          <ListTitle>Breach and Indemnity</ListTitle>
          <OrderedList>
            <ListItem>
              You agree that while the Platform is a secure method of
              transmitting information, however, the risk of any loss of
              information or interception of information by any third party will
              be borne by You. You also agree that Suplias shall not be liable
              for any damages or injuries arising out of or in connection with
              the use of the Platform or its non-use including non-availability
              or failure of performance, loss or corruption of data, loss of or
              damage to property (including profit and goodwill), work stoppage,
              or interruption of business, error, omission, interruption,
              deletion, defect, delay in operation or transmission,
              communication line failure or for any failure to act upon
              instructions. You will at all times indemnify Suplias along with
              their directors, officers, employees, successors and assigns from
              and against all actions, proceedings, claims and demands
              whatsoever for or on account of or in relation to any use of the
              Platform and from and against all damages, costs, charges and
              expenses in respect thereof.
            </ListItem>
            <ListItem>
              You agree that You shall defend, indemnify and hold harmless
              Suplias and its shareholders, directors, partners, officers,
              employees and agents against any and all losses suffered,
              sustained, incurred or paid (including from any third-party claim)
              to the extent arising from breach by You of your obligations under
              this Agreement.
            </ListItem>
            <ListItem>
              In case you breach this T &amp; C and/or other agreements and
              terms that you shall comply with, we shall have the right to
              independently judge according to the circumstance and to adopt
              measures without prior notice to you, including without
              restricting, suspending, or terminating your use of part or all of
              the functions of the Services. You shall be solely responsible for
              any losses incurred thereby.
            </ListItem>
            <ListItem>
              You shall be solely responsible for any third-party complaint,
              litigation or claim arising from your breach of this T &amp; C and
              other terms that you shall comply with.
            </ListItem>
            <ListItem>
              On no account will Suplias and/or MFB or any of the indemnified
              persons above be liable for any demand, claim, loss, or damage
              which you may sustain:
              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  from our acting in accordance with your Requests or purported
                  to be from you;{" "}
                </li>
                <li>
                  from the malfunction or failure or unavailability of any
                  hardware, software, or equipment, the loss or destruction of
                  any data, power failures, corruption of storage media, natural
                  phenomena, riots, acts of vandalism, sabotage, terrorism, any
                  other event out of Suplias&apos; control, interruption or
                  distortion of communication links or arising from reliance on
                  any person or any incorrect, illegible, incomplete or
                  inaccurate information or data contained in any Request
                  received by Suplias and/or MFB;
                </li>
                <li>
                  from any unauthorised access to your Account or any breach of
                  security or any destruction or accessing of your data or any
                  destruction or theft of or damage to any Mobile Phone or other
                  device used to access the Platform;
                </li>
                <li>
                  from any loss or damage occasioned by the failure or
                  unavailability of third-party facilities or systems or the
                  inability of a third party to process a transaction (a third
                  party here includes MFB); or{" "}
                </li>
                <li>
                  from any claims where the circumstance is within your control.
                </li>
              </ul>
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 13 */}
        <ListItem>
          <ListTitle>Availability of the Platform</ListTitle>
          <OrderedList>
            <ListItem>
              While We have put resources into building the Platform, glitches,
              slowdowns, and crashes may occur. We will also need to
              periodically restrict access to some parts of the Platform to
              perform routine maintenance. You understand that We do not
              guarantee that You will always be able to access the Platform.
            </ListItem>
            <ListItem>
              We are constantly improving the Services We provide on the
              Platform and reserve the right to modify or change any of our
              Services, temporarily or permanently with or without prior notice
              to You.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 14 */}
        <ListItem>
          <ListTitle>Termination and Withdrawal</ListTitle>
          <OrderedList>
            <ListItem>
              <ListTitle>Termination</ListTitle>
              <OrderedList>
                <ListItem>
                  You acknowledge and agree that We may restrict, suspend, or
                  terminate the T&amp;C or your access to, and use, of all or
                  any part of the Platform, at any time, with or without cause,
                  including but not limited to any breach of the T&amp;C without
                  prior notice to You.
                </ListItem>
                <ListItem>
                  If you are dissatisfied with any policies, guidelines or
                  practices of Suplias in operating the Platform or MFB, or any
                  change in content, you may discontinue accessing or using the
                  Platform.
                </ListItem>
                <ListItem>
                  We will fully cooperate with any law enforcement authorities
                  or court order requesting or directing Us to disclose the
                  identity of anyone posting, publishing, or otherwise making
                  available any Manufacturer information, emails, or other
                  materials that are believed to violate the T&amp;C.
                </ListItem>
                <ListItem>
                  You agree that Suplias may terminate or temporarily suspend
                  your access to all or any part of the Platform without notice,
                  for conduct that Suplias believes to be in violation of these
                  T&amp;C or any policies or guidelines posted by Suplias, or
                  any other conduct which Suplias, in its sole discretion,
                  believes to be harmful to Suplias or other users.
                </ListItem>
                <ListItem>
                  Suplias may at any time with or without notice or reason and
                  in our sole discretion discontinue operating the Platform, in
                  which case, these T&amp;C will be terminated.
                </ListItem>
                <ListItem>
                  Any suspension, termination, or cancellation of the Platform
                  shall not affect your obligations to Suplias and MFB under
                  these T&amp;C (including but not limited to repayment of a
                  loan, ownership, indemnification, and limitation of
                  liability), which by their sense and context are intended to
                  survive such suspension, termination, or cancellation.
                </ListItem>
                <ListItem>
                  You agree that if for any reason this Platform is not capable
                  of running as planned due to infection by computer virus,
                  bugs, worms, trojan horses, denial of service, attacks,
                  tampering, unauthorised intervention, fraud, technical
                  failures, or any other causes beyond the control of Suplias
                  that corrupt or affect the administration, security, fairness,
                  integrity, or proper conduct of this Platform, Suplias
                  reserves the right, at its sole discretion, to deny access to
                  any individual(s) who tampers with the Platform, and/or to
                  cancel, terminate, modify, or suspend any part of the
                  Platform.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>Withdrawal</ListTitle>
              <Text>
                You have a right to withdraw from the Platform at any time.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 15 */}
        <ListItem>
          <ListTitle>Representation and Warranties</ListTitle>
          <Text>You hereby represent and warrant that:</Text>
          <ul style={{ listStyleType: "lower-alpha" }}>
            <li>You have read and understood the T&amp;C;</li>
            <li>You are at least 18 (eighteen) years of age;</li>
            <li>
              the User Information and any other information given to Us and MFB
              is true, accurate, authentic, current and complete;
            </li>
            <li>
              You have granted to Us the right to use the User Information and
              Personal Information in accordance with the T&amp;C;
            </li>
            <li>
              You agree to comply with and be bound by the T&amp;C governing
              your use of the Platform and You affirm that the T&amp;C are
              without prejudice to any other right that We may have with respect
              to your use of the Platform under the laws of the Federal Republic
              of Nigeria or otherwise;
            </li>
            <li>
              Your acceptance and use of the Platform does not violate any
              applicable law in Nigeria or any contract or obligation to which
              You are a party or are otherwise bound;
            </li>
            <li>
              if You are accepting the T&amp;C on behalf of a company,
              organisation, or other legal entity, that You have their consent
              or corporate authorisation to do so; and
            </li>
            <li>
              You have not entered any agreements (including but not limited to
              agreements with employers) that would prohibit you from using the
              Platform for accessing the Services.
            </li>
          </ul>
        </ListItem>

        {/* chapter 16 */}
        <ListItem>
          <ListTitle>Limitation of Liability</ListTitle>
          <OrderedList>
            <ListItem>
              Under no circumstance shall Suplias, its affiliates, agents,
              licensors, service providers, content providers, directors,
              officers and employees be liable for any direct or incidental,
              indirect, punitive, actual, consequential, special, exemplary or
              other damages including loss of revenue or income, pain and
              suffering, emotional distress or similar damages arising from this
              T&amp;C, the Platform, whether for breach of contract, tortuous
              behaviour, negligence, or any other cause of action, including
              without limitation, any liability for damages caused or allegedly
              caused by any failure of performance, error, omission,
              interruption, electrical surge/damage/interface, deletion, defect,
              delay in operation or transmission, computer virus, communications
              line failure, breakdown of equipment, software error,
              infringement, unauthorised access to or theft, destruction,
              alteration or use of records.
            </ListItem>
            <ListItem>
              Under no circumstances will Suplias be liable for your failure to
              comply with the T&amp;C and any document or information provided
              by Suplias concerning the use of the Platform.
            </ListItem>
            <ListItem>
              Under no circumstances will Suplias be liable for failure to gain
              access to a loan to be provided by MFB via the Platform.
            </ListItem>
          </OrderedList>
        </ListItem>

        {/* chapter 17 */}
        <ListItem>
          <ListTitle>Miscellaneous</ListTitle>
          <OrderedList>
            <ListItem>
              <ListTitle>Entire Agreement</ListTitle>
              <OrderedList>
                <ListItem>
                  This T &amp; C, our Privacy Policy, other applicable terms
                  referred herein and any other applicable terms that we will
                  notify you in a reasonable manner which constitute the entire
                  legal agreements between You and Us supersede extinguish all
                  previous agreements, promises, assurances, warranties,
                  representations, and understandings between us, whether
                  written or oral, relating to its subject matter.
                </ListItem>

                <ListItem>
                  If there are any amendments, updates or supplements in the
                  foregoing terms, the prior version replaced thereby will be
                  invalidated automatically upon the effectiveness of such
                  changes, updates, or amendments.
                </ListItem>
                <ListItem>
                  You acknowledge that in entering into this T &amp; C, you have
                  not relied on any statement, representation, assurance or
                  warranty (whether made innocently or negligently) that is not
                  set out in this T &amp; C or our Privacy Policy.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>No Waiver and Severability</ListTitle>
              <Text>
                Our failure to temporarily insist upon or enforce any provision
                of this T &amp; C shall not be construed as a waiver of any
                provision or right. If any provisions of this T &amp; C are
                ruled invalid, such provision will be deemed to be removed from
                this T &amp; C without affecting the validity of other
                provisions of this T &amp; C.
              </Text>
            </ListItem>

            <ListItem>
              <ListTitle>Assignment</ListTitle>
              <OrderedList>
                <ListItem>
                  You may not assign any of your rights or delegate your
                  obligations under this T &amp; C without our prior written
                  consent as well as that of MFB.
                </ListItem>
                <ListItem>
                  For the purpose of providing the Services, we may assign this
                  T &amp; C in its entirety (including other terms you shall
                  comply with in connection with the Services), to another
                  entity. Where there is a merger, acquisition, corporate
                  reorganisation, or sale of all or substantially all of our
                  assets, we may assign this T &amp; C (including other terms
                  you shall comply with in connection with the Services) to a
                  third party. You acknowledge that we are not required to
                  obtain your consent under the foregoing circumstances.
                  However, we will notify you in a reasonable manner.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>Taxes</ListTitle>
              <OrderedList>
                <ListItem>
                  All payments, including Transaction Fees, to be made by you in
                  connection with this T &amp; C or any loans provided to you by
                  MFB may be calculated without regard to any taxes payable by
                  you or in respect of the Services. If any taxes are payable in
                  connection with the loans you receive, you must ensure that
                  you include such taxes payable by you and that you pay to
                  Suplias and MFB, such additional amounts as will result in
                  Suplias and MFB receiving the full amount it would have
                  received had no such taxes been applicable to the payments.
                </ListItem>
                <ListItem>
                  All payments to be made by You under this T &amp; C shall be
                  made in full without any set off or counter claim and save in
                  so far as required by the law to the contrary, free, and clear
                  of and without any deduction or withholding whatsoever. If you
                  are at any time required to make any deduction or withholding
                  from any payment to Suplias and/or MFB, you shall immediately
                  pay to Suplias and MFB such additional amounts as will result
                  in both of them receiving the full amount they would have
                  received had no such deduction or withholding been required.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>GENERAL</ListTitle>
              <OrderedList>
                <ListItem>
                  A partial exercise of any right, power or remedy shall not
                  preclude any further or other exercise of that, or any other,
                  right, power or remedy. No failure by Suplias or MFB to
                  exercise, and no delay in exercising, any right or remedy in
                  respect of any provision of this T &amp; C shall operate as a
                  waiver of such right or remedy.
                </ListItem>
                <ListItem>
                  If any provision or part of a provision of this T &amp; C is
                  found by any court of competent jurisdiction to be, invalid or
                  unenforceable, such invalidity or unenforceability shall not
                  affect the other provisions or parts of such provisions of
                  this T &amp; C, all of which shall remain in full force and
                  effect.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>Dispute Resolution and Governing Law</ListTitle>
              <OrderedList>
                <ListItem>
                  This T &amp; C shall be governed by and construed in
                  accordance with the laws of the Federal Republic of Nigeria
                  and the Nigerian courts shall have exclusive jurisdiction to
                  determine any dispute arising from the operation of this T
                  &amp; C.
                </ListItem>
                <ListItem>
                  The Parties shall use their good faith efforts to resolve any
                  dispute, controversy or claim of any nature whatsoever arising
                  out of or in relation to or in connection with this T &amp; C.
                </ListItem>
                <ListItem>
                  In relation to any loans and interest payments due, MFB shall
                  have the right and responsibility to recover the monies due.
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem>
              <ListTitle>Contact Us</ListTitle>
              <Text>
                Should you have any questions, please contact us at{" "}
                <Link href="mailto:support@obtainly.com">
                  support@obtainly.com
                </Link>
                . You may also contact us by using the contact form on the
                Platform.
              </Text>
            </ListItem>
          </OrderedList>
        </ListItem>
      </OrderedList>
      <Text fontSize="md" fontWeight="semibold">
        <Text as="span" color="gray.700">
          Signed by:
        </Text>{" "}
        {isLoadingUser
          ? "..."
          : !!user
          ? `${user.firstName} ${user.lastName}`
          : null}
      </Text>
    </Box>
  );
};

export default LoanAgreement;
