import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { LoanRequestModel } from "@obtainly-v2/schema";
import { Card, CardBody } from "components/common/Card";
import { useAuth } from "hooks";
import { FC } from "react";
import LoanRequestCommunication from "./LoanRequestCommunication";
import { LoanRequestHistory } from "./LoanRequestHistory";
import { LoanRequestLog } from "./LoanRequestLog";
import { LoanRequestPenalty } from "./LoanRequestPenalty";

type LoanRequestExtraContentProps = {
  loanRequest: LoanRequestModel;
};

enum PageTabs {
  History,
  Statement,
  Charges,
  Communication,
}

export const LoanRequestExtraContent: FC<LoanRequestExtraContentProps> = ({
  loanRequest,
}) => {
  const {
    auth: { admin },
  } = useAuth();
  return (
    <div>
      <Card py="10px">
        <Tabs>
          <CardBody py="0">
            <TabList>
              <Tab value={PageTabs.History}>History</Tab>
              <Tab value={PageTabs.Statement}>Statement</Tab>
              <Tab value={PageTabs.Charges}>Charges</Tab>
              {admin ? (
                <Tab value={PageTabs.Communication}>Communication</Tab>
              ) : null}
            </TabList>
          </CardBody>
          <TabPanels>
            <TabPanel>
              <LoanRequestHistory loanRequest={loanRequest} />
            </TabPanel>
            <TabPanel>
              <LoanRequestLog loanRequest={loanRequest} />
            </TabPanel>
            <TabPanel>
              <LoanRequestPenalty loanRequest={loanRequest} />
            </TabPanel>
            {admin ? (
              <TabPanel>
                <LoanRequestCommunication loanRequest={loanRequest} />
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
      </Card>
    </div>
  );
};
