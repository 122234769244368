import { Button, VStack } from "@chakra-ui/react";
import { BusinessType, LoanRequestStatus } from "@obtainly-v2/enums";
import {
  AdministratorModel,
  LoanRequestModel,
  UserModel,
} from "@obtainly-v2/schema";
import { BusinessAutocomplete } from "components/business/BusinessAutocomplete";
import { ClientReferenceAutocomplete } from "components/client-reference";
import {
  FormBaseProps,
  FormField,
  FormGroup,
  OtpDialog,
  RoutePrompt,
} from "components/common";
import { getUnixTime } from "date-fns";
import { useFormWithSchema } from "hooks";
import { useToast } from "hooks/useToast";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { loanRequestService } from "services";
import * as Yup from "yup";
import { LoanRequestDocumentsForm } from "./LoanRequestDocumentsForm";

interface LoanRequestProps
  extends FormBaseProps<LoanRequestInputType, LoanRequestModel> {
  user?: UserModel;
  admin?: AdministratorModel;
  loanRequest?: LoanRequestModel;
}

const LoanRequestFormSchema = Yup.object({
  status: Yup.string().required(),
  clientId: Yup.string().required().label("Client"),
  clientReferenceId: Yup.string().required().label("Client Contact"),
  vendorId: Yup.string().required().label("Vendor"),
  creditProviderId: Yup.string().label("Credit Provider").nullable(),
  amount: Yup.number().min(1).required().typeError("Amount must be a number"),
  interest: Yup.number().required().typeError("Interest must be a number"),
  duration: Yup.number().min(1).required().typeError("Select duration"),
  referenceName: Yup.string().nullable(),
  referenceEmail: Yup.string().nullable(),
  referenceRole: Yup.string().nullable(),
  documents: Yup.array()
    .of(
      Yup.object({
        tag: Yup.string().required().label("Document tag"),
        value: Yup.mixed().required().label("Document value"),
        status: Yup.string().required().label("Document status"),
      })
    )
    .required(),
  lastLpoSubmittedAt: Yup.number()
    .min(1, (messageParams) => `Select ${messageParams.label.toLowerCase()}`)
    .required((messageParams) => `${messageParams.label} is required`)
    .label("Date of previous payment from client"),
});

export type LoanRequestInputType = Yup.InferType<typeof LoanRequestFormSchema>;

export const LoanRequestForm: React.FC<LoanRequestProps> = ({
  loanRequest,
  user,
  admin,
  onSubmit,
  onSuccess,
  onError,
}) => {
  const { toast } = useToast();
  const isEditable = (!!user && !loanRequest) || !!admin;
  const [showOtpDialog, setOtpDialog] = useState(false);

  const defaultValues: LoanRequestInputType = {
    status: loanRequest?.status || LoanRequestStatus.Pending,
    clientId: loanRequest?.clientId || "",
    clientReferenceId: loanRequest?.clientReferenceId || "",
    vendorId: loanRequest?.vendorId || user?.businessId || "",
    creditProviderId: loanRequest?.creditProviderId || null,
    amount: loanRequest?.amount || 0,
    interest: loanRequest?.interest || 10,
    duration: loanRequest?.duration || 0,
    referenceEmail: loanRequest?.referenceEmail || null,
    referenceName: loanRequest?.referenceName || null,
    referenceRole: loanRequest?.referenceRole || null,
    documents: loanRequest?.documents || [],
    lastLpoSubmittedAt: loanRequest?.lastLpoSubmittedAt || 0,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    reset: resetForm,
    watch,
    setValue,
    getValues: getFormValues,
  } = useFormWithSchema(LoanRequestFormSchema, {
    defaultValues,
  });

  const { mutate: mutateLoanRequestCreate, isLoading: isCreatingLoanRequest } =
    useMutation(loanRequestService.createWithOtp);
  const { mutate: mutateLoanRequestUpdate, isLoading: isUpdatingLoanRequest } =
    useMutation(loanRequestService.updateWithOtp);

  const [isLoading, setLoading] = React.useState(false);

  const isSubmittingForm = React.useMemo(() => {
    return isCreatingLoanRequest || isUpdatingLoanRequest || isLoading;
  }, [isCreatingLoanRequest, isUpdatingLoanRequest, isLoading]);

  const errorHandler = (error: any) => {
    const message = error?.response?.data?.message || "Unknown error occurred";
    onError?.(new Error(message));
    toast({
      description: message,
      status: "error",
    });
  };

  const successHandler = (
    formValues: LoanRequestInputType,
    data: LoanRequestModel,
    toastMessage: string
  ) => {
    onSuccess?.(data);
    resetForm(formValues);
    toast({
      description: toastMessage,
      status: "success",
    });
  };

  const commitChanges = (values: LoanRequestInputType, otp: string) => {
    return new Promise<void>((resolve, reject) => {
      const serializedValues: Partial<Record<keyof LoanRequestModel, any>> = {
        ...values,
      };
      if (loanRequest) {
        let timestamps: Pick<LoanRequestModel, "approvedAt" | "activatedAt"> =
          {};

        if (values.status === LoanRequestStatus.Approved) {
          timestamps.approvedAt = getUnixTime(new Date());
        } else if (values.status === LoanRequestStatus.Active) {
          timestamps.activatedAt = getUnixTime(new Date());
        }

        const payload = {
          ...loanRequest,
          ...serializedValues,
          ...timestamps,
        } as LoanRequestModel;

        mutateLoanRequestUpdate(
          { params: payload, otp },
          {
            onSuccess: () => {
              successHandler(values, payload, "Updated");
              resolve();
            },
            onError: (error) => {
              errorHandler(error);
              reject();
            },
          }
        );
      } else {
        const formattedDocuments = serializedValues.documents.map(
          (document: any) => ({ ...document, value: document.value._id })
        );
        mutateLoanRequestCreate(
          {
            params: { ...serializedValues, documents: formattedDocuments },
            otp,
          },
          {
            onSuccess: ({ data }) => {
              successHandler(defaultValues, data, "Created");
              resolve();
            },
            onError: (error) => {
              errorHandler(error);
              reject();
            },
          }
        );
      }
    });
  };

  const onFormSubmit = (values: LoanRequestInputType) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(values)
        .then(() => resetForm(values))
        .finally(() => setLoading(false));
    } else {
      initiateOtpVerification();
    }
  };

  const initiateOtpVerification = () => setOtpDialog(true);
  const closeOtpDialog = () => setOtpDialog(false);
  const handleOtpSubmit = (otp: string) => commitChanges(getFormValues(), otp);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <VStack spacing="20px" alignItems="stretch">
        {!!admin && (
          <FormGroup label="Vendor">
            <Controller
              name="vendorId"
              control={control}
              render={({
                field: { ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <BusinessAutocomplete
                  businessType={BusinessType.Vendor}
                  onChange={(data) => {
                    setValue("clientReferenceId", "");
                    onChange({ target: data });
                  }}
                  errorMessage={error?.message}
                  {...rest}
                />
              )}
            />
          </FormGroup>
        )}

        <FormGroup label="Client">
          <Controller
            name="clientId"
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <BusinessAutocomplete
                businessType={BusinessType.Client}
                onChange={(data) => {
                  setValue("clientReferenceId", "");
                  onChange({ target: data });
                }}
                isDisabled={!isEditable}
                errorMessage={error?.message}
                {...rest}
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Client Contact">
          <Controller
            name="clientReferenceId"
            control={control}
            render={({
              field: { ref, onChange, ...rest },
              fieldState: { error },
            }) => (
              <ClientReferenceAutocomplete
                vendorId={watch("vendorId")}
                clientId={watch("clientId")}
                onChange={(data) => onChange({ target: data })}
                isDisabled={!isEditable}
                errorMessage={error?.message}
                {...rest}
              />
            )}
          />
        </FormGroup>

        {!!admin && (
          <FormGroup label="Credit Provider">
            <Controller
              name="creditProviderId"
              control={control}
              render={({
                field: { ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <BusinessAutocomplete
                  businessType={BusinessType.CreditProvider}
                  onChange={(data) => onChange({ target: data })}
                  isDisabled={!isEditable}
                  errorMessage={error?.message}
                  {...rest}
                />
              )}
            />
          </FormGroup>
        )}

        <FormGroup label="Amount">
          <FormField
            type="number"
            autoComplete="amount"
            placeholder="Enter amount"
            isDisabled
            errorMessage={errors.amount?.message}
            {...register("amount")}
          />
        </FormGroup>

        {!!admin && (
          <FormGroup label="Interest Rate (%)">
            <FormField
              type="number"
              autoComplete="interest"
              placeholder="Enter interest rate"
              isDisabled
              errorMessage={errors.interest?.message}
              {...register("interest")}
            />
          </FormGroup>
        )}

        <FormGroup label="How long do you need the loan? (No. of days)">
          <FormField
            autoComplete="duration"
            placeholder="Select how long you need the loan"
            isDisabled
            errorMessage={errors.duration?.message}
            {...register("duration")}
          />
        </FormGroup>

        {!!loanRequest && (
          <FormGroup label="Status">
            <FormField
              as="select"
              autoComplete="status"
              placeholder="Select status"
              isDisabled={!isEditable}
              errorMessage={errors.status?.message}
              {...register("status")}
            >
              {Object.entries(LoanRequestStatus).map(([key, status]) => (
                <option key={key} value={status}>
                  {key}
                </option>
              ))}
            </FormField>
          </FormGroup>
        )}

        <FormGroup label="Documents">
          <LoanRequestDocumentsForm control={control} errors={errors} />
        </FormGroup>

        <Button
          isLoading={isSubmittingForm}
          disabled={isSubmittingForm || !isValid || !isDirty}
          type="submit"
          data-test="submit-loan-request"
          colorScheme="primary"
        >
          {loanRequest ? "Update" : "Submit"} request
        </Button>
        {isDirty && (
          <Button
            disabled={isSubmittingForm}
            data-test="reset-loan-request"
            onClick={() => resetForm(defaultValues)}
          >
            Discard Changes
          </Button>
        )}

        <RoutePrompt
          when={isDirty}
          message="You have unsaved changes. Do you want to leave page?"
        />
      </VStack>
      <OtpDialog
        isOpen={showOtpDialog}
        onClose={closeOtpDialog}
        onSubmit={handleOtpSubmit}
        lastRequestedAt={null}
      />
    </form>
  );
};
