import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack
} from "@chakra-ui/react";
import { LoanRequestStatus } from "@obtainly-v2/enums";
import { AdministratorModel, LoanRequestModel } from "@obtainly-v2/schema";
import { UploadModel } from "@obtainly-v2/schema/src/upload";
import { ClientReferenceDetails } from "components/client-reference";
import { FileUploadField, PoweredBadge } from "components/common";
import { Card, CardBody, CardHeader } from "components/common/Card";
import React, { useCallback, useState } from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useQueryClient } from "react-query";
import { formatUnixTime, getLoanRequestDerivedStatus } from "utils";
import { LoanRequestExtraContent } from "./LoanRequestExtraContent";
import { LoanRequestForm } from "./LoanRequestForm";
import { LoanRequestPaymentSummary } from "./LoanRequestPaymentSummary";
import { LoanRequestStatusBadge } from "./LoanRequestStatusBadge";

interface LoanRequestViewProps {
  loanRequest: LoanRequestModel;
  admin?: AdministratorModel;
  isNew?: boolean;
}

export const LoanRequestView: React.FC<LoanRequestViewProps> = ({
  loanRequest,
  admin,
  isNew,
}) => {
  const [editMode, setEditMode] = useState(false);

  const queryClient = useQueryClient();
  const onEditSuccess = useCallback(() => {
    setEditMode(false);
    queryClient.refetchQueries(`loan_request_${loanRequest?._id}`);
  }, [loanRequest]); // eslint-disable-line

  return (
    <>
      {isNew && loanRequest.status === LoanRequestStatus.Pending && (
        <Alert mb="20px" status="success" borderRadius="8px">
          <AlertIcon as={RiCheckboxCircleFill} />
          <AlertDescription>
            Your funding request has been submitted. We will get back to you
            within the next 24 hours.
          </AlertDescription>
        </Alert>
      )}

      <Grid
        gridGap="20px"
        gridTemplateColumns={["1fr", "1fr", "1fr", "2fr 1fr"]}
      >
        <GridItem>
          <Card py="20px">
            <CardBody py="0">
              <CardHeader
                as={Flex}
                alignItems="center"
                heading="Funding Details"
              >
                {!!admin && (
                  <Button
                    size="sm"
                    variant="link"
                    colorScheme="primary"
                    onClick={() => setEditMode(!editMode)}
                  >
                    {editMode ? "View" : "Edit"}
                  </Button>
                )}
              </CardHeader>
            </CardBody>

            {!editMode || !admin ? (
              <VStack alignItems="stretch" spacing="15px">
                <Flex
                  px="20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {!!admin ? (
                    <>
                      <Box>
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          fontWeight="medium"
                        >
                          Vendor
                        </Text>
                        <Text fontWeight="medium">
                          {loanRequest.vendor?.name}
                        </Text>
                      </Box>
                      <Flex flexDir="column" align="start" justify="end">
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          fontWeight="medium"
                        >
                          Status
                        </Text>
                        <LoanRequestStatusBadge
                          status={loanRequest.status}
                          derivedStatus={getLoanRequestDerivedStatus(
                            loanRequest
                          )}
                        />
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Text fontSize="sm" color="gray.600" fontWeight="medium">
                        Status
                      </Text>
                      <LoanRequestStatusBadge
                        status={loanRequest.status}
                        derivedStatus={getLoanRequestDerivedStatus(loanRequest)}
                      />
                    </>
                  )}
                </Flex>
                <Flex
                  px="20px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {loanRequest.clientReference && (
                    <ClientReferenceDetails
                      clientReference={{
                        ...loanRequest.clientReference,
                        client: loanRequest.client,
                        vendor: loanRequest.vendor,
                      }}
                      gridTemplateColumns={["auto", "auto", "1fr 1fr"]}
                    />
                  )}
                </Flex>

                <Divider />

                <Box px="20px" justifyContent="space-between">
                  <LoanRequestPaymentSummary loanRequest={loanRequest} />
                </Box>

                <Divider />
                <Box px="20px" justifyContent="space-between">
                  <Text fontSize="sm" color="gray.600" fontWeight="medium">
                    Documents
                  </Text>

                  <VStack align="stretch">
                    {loanRequest.documents?.map((document) => (
                      <FileUploadField
                        key={document._id}
                        businessId={(document.value as UploadModel).businessId}
                        tag={document.tag}
                        label={(document.value as UploadModel).name}
                        fileUrl={(document.value as UploadModel).fileUrl}
                        readOnly
                      />
                    ))}
                  </VStack>
                  <Text
                    fontSize="sm"
                    color="gray.600"
                    fontWeight="medium"
                    mt="20px"
                    mb="2px"
                  >
                    Date of previous payment from client
                  </Text>
                  <Text>
                    {loanRequest.lastLpoSubmittedAt
                      ? formatUnixTime(
                          loanRequest.lastLpoSubmittedAt * 1000,
                          "dd MMM yyyy"
                        )
                      : "Not set"}
                  </Text>
                </Box>
              </VStack>
            ) : null}

            {!!admin && editMode ? (
              <CardBody>
                <LoanRequestForm
                  loanRequest={loanRequest}
                  admin={admin}
                  onSuccess={onEditSuccess}
                />
              </CardBody>
            ) : null}
          </Card>
        </GridItem>

        <GridItem>
          <LoanRequestExtraContent loanRequest={loanRequest} />
        </GridItem>
      </Grid>

      <Flex mt="20px" justifyContent={["center", "center", "flex-start"]}>
        <PoweredBadge />
      </Flex>
    </>
  );
};
